import React, { useEffect } from 'react';
import './PasswordResetFinalMessage.css';
import Numbers from './../../../Images/Numbers.jpg';
import { useNavigate } from 'react-router-dom';

function PasswordReset() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Password Resset';
  }, []);

  return (
    <div className="page">
      <div className="register">
        <div className="register-container">
          <h2 className="register-title">Password reset</h2>

          <div className="messange-container final-messange-container">
            <h3 className="messange-title final-messange-title">Operation NOT completed!</h3>
            <p>
              To get a temporary password input confirmation code or click on the link that was sent
              to your e-mail.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
