import React, { useEffect } from 'react';
import './Wallet.css'; // Импортируем CSS стили
import { useSelector, useDispatch } from 'react-redux';
import { setIsOpen, setIsOpenWallet } from './../../../Redux/Slices/MenuSlice';
import BTC from './../../../Images/Token2.svg';
import ETH from './../../../Images/Token3.svg';
import USDT from './../../../Images/Token4.svg';
import USDC from './../../../Images/Token1.svg';
import Tooltip from './../Question/Tooltip';

function Wallet() {
  const isOpenWallet = useSelector((state) => state.menu.isOpenWallet); // Получаем значение из состояния
  const dispatch = useDispatch();

  const currencies = [
    {
      name: 'BTC',
      description: 'Bitcoin',
      available: '0.00000',
      invested: '0.00000',
      icon: BTC,
    },
    {
      name: 'ETH',
      description: 'Ethereum',
      available: '0.00000',
      invested: '0.00000',
      icon: ETH,
    },
    {
      name: 'USDT',
      description: 'TRC-20',
      available: '0.00000',
      invested: '0.00000',
      icon: USDT,
    },
    {
      name: 'USDC',
      description: 'ERC-20',
      available: '0.00000',
      invested: '0.00000',
      icon: USDC,
    },
  ];

  return (
    <div className={`crypto-sidebar ${isOpenWallet ? 'show-crypto-sidebar' : ''}`}>
      <div className="crypto-sidebar-container">
        <div className="cryptos">
          {currencies.map((currency, index) => (
            <div className="crypto-item" key={index}>
              <div className="crypto-info">
                <img src={currency.icon} alt={currency.name} className="crypto-icon" />
                <div className="crypto-name">
                  <h4>{currency.name}</h4>
                  <span>{currency.description}</span>
                </div>
              </div>
              <div className="crypto-status">
                <p className="available">
                  Available: {currency.available} <span>{currency.name}</span>
                </p>
                <p className="invested">
                  Invested: {currency.invested} <span>{currency.name}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
        <div
          onClick={() => dispatch(setIsOpenWallet(false))}
          className="btn-wallet close-crypto-panel">
          Close the wallet
        </div>
      </div>
    </div>
  );
}

export default Wallet;
