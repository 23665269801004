import React, { useEffect, useState } from 'react';
import './LeftPanel.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setIsOpen, setIsOpenWallet } from './../../../Redux/Slices/MenuSlice';
import { setAuthenticated } from './../../../Redux/Slices/AuthenticationSlice';

import WestProtocolLogo from './../../../Images/WestProtocolLogo.svg';

function LeftPanel({ PersonalAccount }) {
  const navigate = useNavigate(); // Хук для навигации
  const location = useLocation();

  const [activeItem, setActiveItem] = useState('/dashboard'); // Отслеживаем активный пункт
  const [width, setWidth] = useState(window.innerWidth);
  const isOpen = useSelector((state) => state.menu.isOpen); // Получаем значение из состояния
  const isOpenWallet = useSelector((state) => state.menu.isOpenWallet); // Получаем значение из состояния
  const dispatch = useDispatch();
  const controlWidth = 1000;

  const handleNavigation = (path) => {
    setActiveItem(path); // Устанавливаем активный элемент
    navigate(path); // Навигация на выбранную страницу
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (window.innerWidth > controlWidth) {
        dispatch(setIsOpen(true));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (PersonalAccount && PersonalAccount.current) {
      console.log(PersonalAccount.current);
      PersonalAccount.current.scrollTo(0, 0);
    }
  }, [location]);

  const logOut = () => {
    dispatch(setAuthenticated(false));
    navigate('/');
  };

  return (
    <div className={isOpen ? 'left-panel' : 'left-panel close'}>
      <div className="sidebar">
        <nav className="sidebar-nav">
          <div className="sidebar-logo">
            <img src={WestProtocolLogo} alt="West Protocol Logo" className="logo-img" />
            <span className="logo-text">
              WEST <br />
              <span className="protocol">PROTOCOL</span>
            </span>
          </div>
          <p className="menu-title">MENU</p>
          <p className="sidebar-available-balance">
            Available: <span>$300</span>
          </p>
          <ul className="sidebar-nav-list">
            <li className={`sidebar-nav-list-item ${activeItem === '/dashboard' ? 'active' : ''}`}>
              <button onClick={() => handleNavigation('/dashboard')}>
                <i className="icon icon-dashboard"></i> Dashboard
              </button>
            </li>
            <li className={`sidebar-nav-list-item ${activeItem === '/invest' ? 'active' : ''}`}>
              <button onClick={() => handleNavigation('/invest')}>
                <i className="icon icon-invest"></i> Invest
              </button>
            </li>
            <li className={`sidebar-nav-list-item ${activeItem === '/withdraw' ? 'active' : ''}`}>
              <button onClick={() => handleNavigation('/withdraw')}>
                <i className="icon icon-withdraw"></i> Withdraw
              </button>
            </li>
            <li className={`sidebar-nav-list-item ${activeItem === '/partners' ? 'active' : ''}`}>
              <button onClick={() => handleNavigation('/partners')}>
                <i className="icon icon-partners"></i> Partners
              </button>
            </li>
            <li className={`sidebar-nav-list-item ${activeItem === '/settings' ? 'active' : ''}`}>
              <button onClick={() => handleNavigation('/settings')}>
                <i className="icon icon-settings"></i> Settings
              </button>
            </li>
            <li className={`sidebar-nav-list-item ${activeItem === '/logout' ? 'active' : ''}`}>
              <button onClick={logOut}>
                <i className="icon icon-logout"></i> Log Out
              </button>
            </li>
          </ul>
        </nav>
        <div className="sidebar-buttons">
          <button className="sidebar-btn sidebar-deposit" onClick={() => navigate('/deposit')}>
            Deposit
          </button>
          <button
            className="sidebar-btn sidebar-wallet"
            onClick={() => dispatch(setIsOpenWallet(!isOpenWallet))}>
            Wallet
          </button>
          <button className="sidebar-btn">
            Public chat <i className="icon sidebar-btn-icon"></i>
          </button>
          <button className="sidebar-btn video-btn">
            Video Tutorials <i className="icon video-btn-icon"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default LeftPanel;
