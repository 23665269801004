import React, { useEffect } from 'react';
import './AddFunds.css';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from './../../../Images/QRCode.svg';
import Token2 from './../../../Images/Token2.svg';
import Tooltip from '../Question/Tooltip';

function AddFunds() {
  const currency = useSelector((state) => state.invest.currency); // Получаем значение из состояния

  useEffect(() => {
    console.log(currency);
  }, [currency]);

  useEffect(() => {
    document.title = 'Invest';
  }, []);

  return (
    <div class="add-funds main-block-container">
      <h2 class="main-block-title">Add funds</h2>
      <div className="add-funds-container">
        <div class="left-section">
          <div class="network-block">
            <span className="network-select">Network:</span>
            <div className="">
              <span>{currency.name || 'Bitcoin'}</span> <span>{currency.currency || 'BTC'}</span>
            </div>
            <Tooltip />
          </div>

          <div class="referral-profit">
            <div className="left-part">
              Profit from referrals: <span>$300</span>
            </div>
            <div class="profit-amount-add-funds">
              <span class="btc-label-funds">
                {currency.currency || 'BTC'}
                <img src={currency.logo || Token2} alt="BTC" class="btc-icon" />
              </span>
            </div>
          </div>

          <div class="qr-code-block">
            <img src={QRCode} alt="QR Code" class="qr-code" />
          </div>

          <div className="referral-link-block addres-block">
            <p>Or copy address:</p>
            <div className="addres-copy-block">
              <span>vhbjkbhvkjbklnnoihb jlknasca</span>
              <button className="copy-btn">COPY</button>
            </div>
          </div>
        </div>

        <div className="table Operation-table">
          <h3>Operation detail</h3>
          <div className="operations-table">
            <div className="table-row table-row-operation">
              <div className="table-row-cell">
                <span className="table-row-cell-name">Profit</span>
                <span className="table-row-cell-value">
                  1% <span>daily</span>
                </span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Plan:</span>
                <span className="table-row-cell-value">Sniper Bot</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Date:</span>
                <span className="table-row-cell-value">Jun13,2024</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Status:</span>
                <span className="table-row-cell-value status">
                  <i className="green-dot"></i> Add funds
                </span>
              </div>
            </div>

            <div className="table-row table-row-operation">
              <div className="table-row-cell">
                <span className="table-row-cell-name">Network:</span>
                <span className="table-row-cell-value">BTC</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Amount:</span>
                <span className="table-row-cell-value">
                  0.0002 <span>BTC</span>
                </span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Type:</span>
                <span className="table-row-cell-value">Add founds</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">TX:</span>
                <span className="table-row-cell-value">-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFunds;
