import { configureStore } from '@reduxjs/toolkit';
import AuthenticationSlice from './Slices/AuthenticationSlice'; // Пример редюсера
import MenuSlice from './Slices/MenuSlice';
import InvestSlice from './Slices/InvestSlice';

const store = configureStore({
  reducer: {
    authentication: AuthenticationSlice, // Укажите ваши редюсеры здесь
    menu: MenuSlice,
    invest: InvestSlice,
  },
});

export default store;
