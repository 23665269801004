import React, { useEffect, useState } from 'react';
import './Settings.css';
import QRCode from './../../../Images/QRCode.svg';
import Token1 from './../../../Images/Token1.svg';
import Token2 from './../../../Images/Token2.svg';
import Token3 from './../../../Images/Token3.svg';
import Token4 from './../../../Images/Token4.svg';
import Token5 from './../../../Images/Token5.svg';

function Settings() {
  const [selectedCurrency, setSelectedCurrency] = useState({});
  useEffect(() => {
    document.title = 'Settings';
  }, []);

  return (
    <div className="main-block-container">
      <h2 className="main-block-title">Settings</h2>

      <div className="settings-container">
        <div className="settings-grid">
          {/* Personal Information Section */}
          <div className="settings-grid-top">
            <div className="settings-card">
              <h3>Personal information</h3>
              <form className="settings-form">
                <div className="form-group floating-label">
                  <input type="email" id="email" placeholder=" " required />
                  <label htmlFor="email">E-Mail</label>
                </div>
                <div className="form-group floating-label">
                  <input type="text" id="fullName" placeholder=" " />
                  <label htmlFor="fullName">Full Name</label>
                </div>
                <div className="form-group floating-label">
                  <input type="text" id="twitter" placeholder=" " />
                  <label htmlFor="twitter">Twitter</label>
                </div>
                <div className="form-group floating-label">
                  <input type="text" id="telegram" placeholder=" " />
                  <label htmlFor="telegram">Telegram</label>
                </div>
              </form>
              <button type="submit" className="save-btn">
                Save
              </button>
            </div>

            {/* Change Password Section */}
            <div className="settings-card">
              <h3>Change Password</h3>
              <form className="settings-form">
                <div className="form-group floating-label long-top">
                  <input type="password" id="currentPassword" placeholder=" " />
                  <label htmlFor="currentPassword">Current Password</label>
                </div>
                <div className="form-group floating-label">
                  <input type="password" id="newPassword" placeholder=" " />
                  <label htmlFor="newPassword">New Password</label>
                </div>
                <div className="form-group floating-label">
                  <input type="password" id="repeatPassword" placeholder=" " />
                  <label htmlFor="repeatPassword">Repeat Password</label>
                </div>
              </form>
              <button type="submit" className="save-btn">
                Save
              </button>
            </div>
          </div>

          <div className="settings-grid-bottom">
            {/* Security Settings Section */}
            <div className="settings-card">
              <h3>Security Settings</h3>
              <form className="settings-form security-settings">
                <div className="form-group floating-label">
                  <input type="text" id="timezone" placeholder=" " />
                  <label htmlFor="timezone">Your time zone from GMT</label>
                </div>
                <div className="form-group floating-label">
                  <input type="number" id="autoLogout" placeholder=" " />
                  <label htmlFor="autoLogout">Auto logout after N Minutes (0 by default)</label>
                </div>
              </form>
              <div className="settings-checkbox-group">
                <label>
                  <input type="checkbox" />
                  Don’t send me email notifications
                </label>
                <label>
                  <input type="checkbox" />
                  Bind a session to an IP address
                </label>
                <label>
                  <input type="checkbox" />
                  Prohibit parallel sessions
                </label>
              </div>
              <button type="submit" className="save-btn">
                Save
              </button>
            </div>

            {/* Google Authenticator Section */}
            <div className="settings-card">
              <h3>Google Authenticator</h3>
              <div cr className="google-auth-container">
                <div className="qr-block">
                  <img src={QRCode} className=""></img>
                  <div className="qr-text">
                    <span>To activate Google Authenticator, use the key:</span>
                    <p className="auth-key">CHUSHXTAZTAW5XNP</p>
                  </div>
                </div>
                <div className="auth-info">
                  <div className="form-group floating-label">
                    <input type="text" placeholder=" " />
                    <label>Enter the code from the application</label>
                  </div>
                </div>
                <button className="save-btn">Save</button>
              </div>
            </div>
          </div>
        </div>

        <div className="personal-information-block">
          <h3>Personal information</h3>

          <div className="personal-information-container">
            <div className="personal-information-block-left">
              {[
                { currency: 'BTC', name: 'Bitcoin', logo: Token2 },
                { currency: 'ETH', name: 'Etherium', logo: Token3 },
                { currency: 'USDT', name: 'TRX20', logo: Token4 },
                { currency: 'USDC', name: 'ERC20', logo: Token1 },
                { currency: 'BTC1', name: 'Bitcoin', logo: Token2 },
                { currency: 'ETH1', name: 'Etherium', logo: Token3 },
                { currency: 'USDT1', name: 'TRX20', logo: Token4 },
                { currency: 'USDC1', name: 'ERC20', logo: Token1 },
              ].map(({ currency, name, logo }) => (
                <div
                  className={`currency-option ${
                    selectedCurrency.currency === currency ? 'active-currency' : ''
                  }`}
                  onClick={() => setSelectedCurrency({ currency, name, logo })}
                  key={currency}>
                  <img src={logo} alt="" />
                  <p className="currency-option-text ">
                    {currency} <span>{name}</span>
                  </p>
                </div>
              ))}
            </div>
            <div className="personal-information-block-right">
              <p>
                In this section you can save the Wallets that you are going to use to withdraw to.
              </p>
              <div className="form-group floating-label crypto-label">
                <input type="text" id="fullName" placeholder=" " />
                <label htmlFor="fullName">Enter wallet details BTC (Bitcoin)</label>
                <i></i>
              </div>
              <button type="submit" className="save-btn">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
