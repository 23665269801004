import React, { useEffect } from 'react';
import './Security.css';
import DataCenterIllustration from './../../../Images/DataCenterIllustration.png';
import Partner1 from './../../../Images/Partner1.svg';
import Partner2 from './../../../Images/Partner2.svg';
import Partner3 from './../../../Images/Partner3.svg';
import Partner4 from './../../../Images/Partner4.svg';

import Token1 from './../../../Images/Token1.svg';
import Token2 from './../../../Images/Token2.svg';
import Token3 from './../../../Images/Token3.svg';
import Token4 from './../../../Images/Token4.svg';
import Token5 from './../../../Images/Token5.svg';

function Security() {
  useEffect(() => {
    document.title = 'Security';
  }, []);

  return (
    <div className="page">
      <section class="data-centers delete-border-radius">
        <div class="data-centers-wrapper">
          <div class="data-centers-info">
            <h2>
              Several data <br /> centers in different countries
            </h2>
            <div class="data-centers-stats">
              <div class="stats-item">
                <p>Capacity of our data centers</p>
                <h3>» 1MW</h3>
              </div>
              <div class="stats-item stats-item-right">
                <p className="green">
                  <i className="green-dot"></i> Uptime of work
                </p>
                <h3 class="green">» 99%</h3>
              </div>
            </div>
          </div>
          <div class="data-centers-image">
            <img src={DataCenterIllustration} alt="Data center illustration" />
          </div>
        </div>

        <div class="data-centers-features">
          <div class="feature-item">
            <p>Distributed system with a high level of security.</p>
          </div>
          <div class="feature-item">
            <p>Readiness to scale and deploy new capacities.</p>
          </div>
          <div class="feature-item">
            <p>No problems with equipment downtime.</p>
          </div>
          <div class="feature-item">
            <p>24/7 operation of all systems.</p>
          </div>
        </div>
      </section>

      <section class="partners">
        <div class="partners-content">
          <div class="partner-logos">
            <div class="partner-logo partner-info">
              <h2>Our Partners</h2>
              <p>
                We use the best data centers in the world and do not forget about diversification.
              </p>
            </div>
            <div class="partner-logo">
              <img src={Partner1} alt="Amazon Web Services" />
            </div>
            <div class="partner-logo">
              <img src={Partner2} alt="IBM Cloud" />
            </div>
            <div class="partner-logo">
              <img src={Partner3} alt="Google Cloud" />
            </div>
            <div class="partner-logo">
              <img src={Partner4} alt="Microsoft Azure" />
            </div>
          </div>
        </div>
      </section>

      <section class="transactions-section delete-border-radius">
        <div className="transactions-section-container">
          <h2 class="transactions-title delete-border-radius">Top transactions in recent times</h2>
          <div class="transactions-table">
            <div class="transaction-row">
              <div class="cell details">
                <span>Details</span>
                <p>0x22af...b99d</p>
              </div>
              <div className="scroll-block">
                <div class="cell cost">
                  <span>Cost</span>
                  <p>$9018.00</p>
                </div>
                <div class="cell revenue">
                  <span>Revenue</span>
                  <p>$47321.00</p>
                </div>
                <div class="cell tokens-flow">
                  <span>Tokens flow</span>
                  <div className="token-images">
                    <img src={Token1} alt="BTC" />
                    <img src={Token2} alt="ETH" />
                    <img src={Token3} alt="USDT" />
                    <img src={Token4} alt="ETH" />
                    <img src={Token5} alt="USDT" />
                  </div>
                </div>
              </div>
            </div>
            <div class="transaction-row">
              <div class="cell details">
                <span>Details</span>
                <p>0x22af...b99d</p>
              </div>
              <div className="scroll-block">
                <div class="cell cost">
                  <span>Cost</span>
                  <p>$9018.00</p>
                </div>
                <div class="cell revenue">
                  <span>Revenue</span>
                  <p>$47321.00</p>
                </div>
                <div class="cell tokens-flow">
                  <span>Tokens flow</span>
                  <div className="token-images">
                    <img src={Token1} alt="BTC" />
                    <img src={Token2} alt="ETH" />
                    <img src={Token3} alt="USDT" />
                    <img src={Token4} alt="ETH" />
                    <img src={Token5} alt="USDT" />
                  </div>
                </div>
              </div>
            </div>
            <div class="transaction-row">
              <div class="cell details">
                <span>Details</span>
                <p>0x22af...b99d</p>
              </div>
              <div className="scroll-block">
                <div class="cell cost">
                  <span>Cost</span>
                  <p>$9018.00</p>
                </div>
                <div class="cell revenue">
                  <span>Revenue</span>
                  <p>$47321.00</p>
                </div>
                <div class="cell tokens-flow">
                  <span>Tokens flow</span>{' '}
                  <div className="token-images">
                    <img src={Token1} alt="BTC" />
                    <img src={Token2} alt="ETH" />
                    <img src={Token3} alt="USDT" />
                    <img src={Token4} alt="ETH" />
                    <img src={Token5} alt="USDT" />
                  </div>
                </div>
              </div>
            </div>
            <div class="transaction-row">
              <div class="cell details">
                <span>Details</span>
                <p>0x22af...b99d</p>
              </div>
              <div className="scroll-block">
                <div class="cell cost">
                  <span>Cost</span>
                  <p>$9018.00</p>
                </div>
                <div class="cell revenue">
                  <span>Revenue</span>
                  <p>$47321.00</p>
                </div>
                <div class="cell tokens-flow">
                  <span>Tokens flow</span>
                  <div className="token-images">
                    <img src={Token1} alt="BTC" />
                    <img src={Token2} alt="ETH" />
                    <img src={Token3} alt="USDT" />
                    <img src={Token4} alt="ETH" />
                    <img src={Token5} alt="USDT" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Security;
