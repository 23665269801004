import React, { useEffect } from 'react';
import './About.css';
import FAQItem from '../../FAQItem/FAQItem';

function About() {
  const faqData = [
    {
      question: 'How to make a deposit for MEV bot operations?',
      answer:
        'To make a deposit, please click the "Sign Up" button and complete the registration process. Once registered, navigate to the "Investment" section and choose the investment plan that best suits your needs. Select the cryptocurrency you wish to invest in, specify the desired amount, and click "Invest" to complete the transaction.',
    },
    {
      question: 'Which cryptocurrencies do you accept?',
      answer:
        'We accept a wide range of popular cryptocurrencies, including Bitcoin, Ethereum, and more.',
    },
    {
      question: 'Deposit limits and quantity',
      answer:
        'The minimum deposit amount is $100. Funds will be credited depending on network conditions. If we detect that a user has more than one account, all funds will be held until further notice. There is no limit to the number of deposits you can make.',
    },
    {
      question: 'How are interest rates calculated on my account?',
      answer: 'Interest rates are calculated based on your selected investment plan.',
    },
    {
      question: 'How are interest rates calculated on my account?',
      answer: 'Interest rates are calculated based on your selected investment plan.',
    },
    {
      question: 'When can I withdraw my investment and profits?',
      answer:
        'We will process your withdrawal request as soon as possible, but please allow some time for the network to confirm the transaction. Your investment will be returned to you at the end of the investment period. You will receive your initial earnings exactly 24 hours after making the deposit. You can withdraw funds at any time. The time it takes to process a withdrawal depends on the cryptocurrency network, but there are no delays on our end. Typically, funds are credited to your wallet within a few minutes after the request is confirmed. The maximum processing time for a withdrawal is 24 hours.',
    },
    {
      question: 'What if I cant log into my account because I forgot my password?',
      answer:
        'If you forget your password, click the "Forgot Password" button and enter your username or email address. You will receive a password reset email.',
    },
    {
      question: 'Who manages the funds and what are the guarantees?',
      answer:
        'Funds are managed by an automated algorithm of the MEV Bot network. We are a licensed and registered company, our activities are fully legal, and we have all the necessary licenses and permissions. Our reputation has been validated by more than 5,000 satisfied investors, and we receive numerous positive reviews. We are regularly featured in the media.',
    },
    {
      question: 'Is it possible to use the WEST PROTOCOL platform on mobile devices?',
      answer:
        'Yes, the Unimatic Protocol platform is available for use on mobile devices. We have optimized the site for easy access to your account and management anytime, anywhere.',
    },
  ];

  useEffect(() => {
    document.title = 'About';
  }, []);

  return (
    <div className="page">
      <section className="home-section delete-border-radius">
        <div className="container">
          <h1 className="main-heading">About Us</h1>
          <p className="subheading">
            The MEV bots of West are complex automated trading algorithms used to generate profit in
            cryptocurrency markets, particularly in trading memecoins. These bots utilize a strategy
            known as "sandwich" trading.
          </p>
        </div>
      </section>

      <section class="how-it-works">
        <h2>Here's how it works:</h2>

        <div className="stages-container">
          <div class="stage">
            <div class="stage-info">
              <h3>Market Monitoring</h3>
              <p>
                MEV bots continuously analyze market activity in real-time, tracking trading volumes
                and buy or sell orders to identify opportunities for transactions.
              </p>
            </div>
            <div className="stage-table-container stage-table-container_1">
              <div class="stage-table">
                <div class="order order-empty"></div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,350.00</span>
                    <span>
                      <i></i> -0.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span>
                      <i></i> -2.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span className="order-info-percent">
                      <i></i> -2.2%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="stage">
            <div class="stage-info">
              <h3>Target Order Identification</h3>
              <p>
                When the bot detects a large buy order that could significantly affect the price of
                a coin, it prepares for action. This large order becomes the "target" for the bot.
              </p>
            </div>
            <div className="stage-table-container stage-table-container_2">
              <div class="stage-table">
                <div class="order red">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,350.00</span>
                    <span className="order-info-percent-red">
                      <i></i> -0.2%
                    </span>
                  </div>
                </div>
                <div class="order green green-mini">
                  <span>BUY</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span className="order-info-percent-green">
                      <i></i>+2.2%
                    </span>
                  </div>
                </div>
                <div class="order green">
                  <span>BUY</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span className="order-info-percent-green">
                      <i></i>+2.2%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="stage">
            <div class="stage-info">
              <h3>Placing Own Orders</h3>
              <p>
                The MEV bot immediately places its own buy order at a price slightly above the
                current market price to "push" the target order, then quickly places a sell order at
                an even higher price. As a result, the bot buys the assets before the large order is
                executed and sells them right after its execution, when the price is expected to
                rise.
              </p>
            </div>
            <div className="stage-table-container stage-table-container_3">
              <div class="stage-table">
                <div class="order order-empty"></div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,350.00</span>
                    <span>
                      <i></i>-0.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span>
                      <i></i>-2.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span className="order-info-percent">
                      <i></i>-2.2%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="stage">
            <div class="stage-info">
              <h3>Strategy Execution</h3>
              <p>
                As the target order begins to be executed, it raises the market price to the level
                where the bot has already set its sell order. This allows the bot to sell the assets
                at a higher price than the purchase price, profiting from the difference.
              </p>
            </div>
            <div className="stage-table-container stage-table-container_4">
              <div class="stage-table">
                <div class="order order-empty"></div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,350.00</span>
                    <span>
                      <i></i>-0.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span>
                      <i></i>-2.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span className="order-info-percent">
                      <i></i>-2.2%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="stage">
            <div class="stage-info">
              <h3>Role of Investors</h3>
              <p>
                Investor funds are used to maintain and increase the volume of operations that the
                MEV bot can perform. These funds allow the bot to place orders for large enough
                amounts to influence market prices and profit from those fluctuations. Larger
                capital also helps to spread risk and participate in a wider range of crypto
                networks, thereby increasing profits.
              </p>
            </div>
            <div className="stage-table-container stage-table-container_5">
              <div class="stage-table">
                <div class="order order-empty"></div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,350.00</span>
                    <span>
                      <i></i>-0.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span>
                      <i></i>-2.2%
                    </span>
                  </div>
                </div>
                <div class="order">
                  <span>SELL</span>
                  <div className="order-info">
                    <span>$12,100.00</span>
                    <span className="order-info-percent">
                      <i></i>-2.2%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="FAQ-section delete-border-radius">
        <h2>FAQ</h2>
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </section>
    </div>
  );
}

export default About;
