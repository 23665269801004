import { createSlice } from '@reduxjs/toolkit';

const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: { isAuthenticated: false },
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAuthenticated } = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
