import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PersonalAccountPagesHeader.css';
import { useSelector, useDispatch } from 'react-redux';
import { setIsOpen, setIsOpenWallet } from './../../../Redux/Slices/MenuSlice';

function PersonalAccountPagesHeader() {
  const navigate = useNavigate(); // Хук для навигации
  const isOpen = useSelector((state) => state.menu.isOpen); // Получаем значение из состояния
  const isOpenWallet = useSelector((state) => state.menu.isOpenWallet); // Получаем значение из состояния
  const dispatch = useDispatch();
  const [isLangOpen, setIsLangOpen] = useState(false); // Состояние для открытия меню языков
  const [selectedLang, setSelectedLang] = useState('EN'); // Состояние для выбранного языка

  const selectLanguage = (lang) => {
    setSelectedLang(lang); // Установка выбранного языка
    setIsLangOpen(false); // Закрытие выпадающего меню после выбора
  };
  const toggleLangMenu = () => {
    setIsLangOpen(!isLangOpen);
  };

  return (
    <div className="pa-header">
      <div onClick={() => dispatch(setIsOpen(!isOpen))} className="open-left-panel">
        <div className="open-left-panel-item"></div>
        <div className="open-left-panel-item"></div>
        <div className="open-left-panel-item"></div>
      </div>
      <div className="pa-header-left">
        <i className="icon-user"></i>
        <span className="username">User Name</span>
      </div>
      <div className="pa-header-middle">
        <span className="available-balance">
          Available: <span>$300</span>
        </span>
      </div>
      <div className="pa-header-right">
        <div className="lang-dropdown">
          <button className="lang-btn" onClick={toggleLangMenu}>
            <i className="flag-icon" /> {selectedLang}{' '}
            <i className={`row-icon ${isLangOpen ? 'open' : ''}`} />
          </button>
          {isLangOpen && (
            <ul className="lang-menu">
              <li onClick={() => selectLanguage('EN')}>
                <i className="flag-icon" />
                EN
              </li>
              <li onClick={() => selectLanguage('FR')}>
                <i className="flag-icon" />
                FR
              </li>
              <li onClick={() => selectLanguage('DE')}>
                <i className="flag-icon" />
                DE
              </li>
              <li onClick={() => selectLanguage('ES')}>
                <i className="flag-icon" />
                ES
              </li>
            </ul>
          )}
        </div>
        <button className="btn-deposit">Deposit</button>
        <button className="btn-wallet" onClick={() => dispatch(setIsOpenWallet(!isOpenWallet))}>
          Wallet
        </button>
      </div>
    </div>
  );
}

export default PersonalAccountPagesHeader;
