import React, { useEffect } from 'react';
import './Bounty.css';
import FAQItem from '../../FAQItem/FAQItem';

function Bounty() {
  const faqData = [
    {
      question: 'What is the West Protocol Social Media Bounty Program?',
      answer:
        'To make a deposit, please click the "Sign Up" button and complete the registration process. Once registered, navigate to the "Investment" section and choose the investment plan that best suits your needs. Select the cryptocurrency you wish to invest in, specify the desired amount, and click "Invest" to complete the transaction.',
    },
    {
      question: 'Who is eligible to participate in the Bounty Program?',
      answer:
        'We accept a wide range of popular cryptocurrencies, including Bitcoin, Ethereum, and more.',
    },
    {
      question: 'How do I sign up for the Bounty Program?',
      answer:
        'The minimum deposit amount is $100. Funds will be credited depending on network conditions. If we detect that a user has more than one account, all funds will be held until further notice. There is no limit to the number of deposits you can make.',
    },
    {
      question: 'What kind of content qualifies for rewards?',
      answer: 'Interest rates are calculated based on your selected investment plan.',
    },
    {
      question: 'How is my content evaluated for rewards?',
      answer:
        'We will process your withdrawal request as soon as possible, but please allow some time for the network to confirm the transaction. Your investment will be returned to you at the end of the investment period. You will receive your initial earnings exactly 24 hours after making the deposit. You can withdraw funds at any time. The time it takes to process a withdrawal depends on the cryptocurrency network, but there are no delays on our end. Typically, funds are credited to your wallet within a few minutes after the request is confirmed. The maximum processing time for a withdrawal is 24 hours.',
    },
    {
      question: 'Is there a limit on how many posts I can submit?',
      answer:
        'If you forget your password, click the "Forgot Password" button and enter your username or email address. You will receive a password reset email.',
    },
    {
      question: 'How and when will I receive my rewards?',
      answer:
        'Funds are managed by an automated algorithm of the MEV Bot network. We are a licensed and registered company, our activities are fully legal, and we have all the necessary licenses and permissions. Our reputation has been validated by more than 5,000 satisfied investors, and we receive numerous positive reviews. We are regularly featured in the media.',
    },
    {
      question: 'What should I do if I have additional questions?',
      answer:
        'If you have any questions not covered here, please feel free to reach out to our support team via the contact details on our Bounty Program page. We’re here to help you make the most out of your participation!',
    },
  ];

  useEffect(() => {
    document.title = 'Bounty';
  }, []);

  return (
    <div className="page">
      <section className="home-section delete-border-radius">
        <div className="home-section-container">
          <h2 class="home-section-heading">Monetize your social media with West Protocol.</h2>
          <div class="monetization-content">
            <div class="monetization-block monetization-block-image">
              <div class="monetization-image youtube"></div>
              <p class="monetization-subtext">Create a YouTube Video or Shorts and earn</p>
              <h3>$50 for every 1000 views.</h3>
            </div>

            <div class="monetization-block">
              <div class="monetization-image social"></div>
              <p class="monetization-subtext">Create a post about us on your social media</p>
              <h3>$10 if you have 100+ followers.</h3>
            </div>
          </div>
        </div>
      </section>

      <section class="section-how-it-works how-it-works">
        <h2 className="instruction-title">How bounty program works?</h2>

        <div className="bounty-program-container">
          <div class="bounty-stage">
            <div className="bounty-stage-table-container">
              <div class="bounty-stage-table">
                <p>
                  Create a video or create a post on your social media. If it is a video, make sure
                  it gets at least 1000 views. If it is a post, you must have at least 50 friends
                </p>
              </div>
            </div>
          </div>

          <div class="bounty-stage">
            <div className="bounty-stage-table-container">
              <div class="bounty-stage-table">
                <p>
                  For videos, wait until it gets more than 1000 views. It’s better to wait for even
                  more views because you can only receive a reward for a video once.
                </p>
              </div>
            </div>
          </div>

          <div class="bounty-stage">
            <div className="bounty-stage-table-container">
              <div class="bounty-stage-table">
                <p>
                  Contact us on Telegram at <a href="#">@WestProtocolSocials</a> and send the link
                  to your post/video that meets the criteria.
                </p>
              </div>
            </div>
          </div>

          <div class="bounty-stage">
            <div className="bounty-stage-table-container">
              <div class="bounty-stage-table">
                <p>
                  After verification, our operator will ask for your USDT TRC-20 wallet address and
                  send the reward.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="FAQ-section delete-border-radius">
        <h2>FAQ</h2>
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </section>
    </div>
  );
}

export default Bounty;
