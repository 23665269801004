import React, { useEffect } from 'react';
import './SingUp.css';
import Numbers from './../../../Images/Numbers.jpg';
import { useNavigate } from 'react-router-dom';
import { setAuthenticated } from './../../../Redux/Slices/AuthenticationSlice';
import { useSelector, useDispatch } from 'react-redux';

function SingUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Create = () => {
    dispatch(setAuthenticated(true));
    navigate('/dashboard');
  };

  useEffect(() => {
    document.title = 'Sing Up';
  }, []);

  return (
    <div className="page">
      <div className="register">
        <div className="register-container">
          <h2 className="register-title">Create an account</h2>

          <form className="register-form">
            <div className="input-group">
              <input className="input-register" type="email" placeholder="E-mail" required />
            </div>

            <div className="input-group-row">
              <input className="input-register" type="password" placeholder="Password" required />
              <input
                className="input-register"
                type="password"
                placeholder="Confirm password"
                required
              />
            </div>

            <div className="input-group-row">
              <input
                className="input-register input-register-code"
                type="text"
                placeholder="Code from image"
                required
              />
              <div className="captcha-image">
                <img src={Numbers} alt="Captcha" />
              </div>
            </div>

            <div className="checkbox-group">
              <input className="input-register" type="checkbox" id="agreement" required />
              <label htmlFor="agreement">
                Agree with <a onClick={() => navigate('/Policy')}>rules and agreements</a>, as well
                as potential risks.
              </label>
            </div>

            <button onClick={Create} type="submit" className="create-account-btn">
              Create account
            </button>
          </form>

          <div className="form-footer">
            <a onClick={() => navigate('/PasswordReset')}>Forgot Your Password?</a>
            <span>
              Already have an account? <a onClick={() => navigate('/LogIn')}>Login</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingUp;
