import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import WestProtocolLogo from './../../Images/WestProtocolLogo.svg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false); // Состояние для открытия меню языков
  const [selectedLang, setSelectedLang] = useState('EN'); // Состояние для выбранного языка

  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleLangMenu = () => {
    setIsLangOpen(!isLangOpen);
  };

  const selectLanguage = (lang) => {
    setSelectedLang(lang); // Установка выбранного языка
    setIsLangOpen(false); // Закрытие выпадающего меню после выбора
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active-link' : '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <header className="header">
      <div onClick={() => navigate('/')} className="logo">
        <img src={WestProtocolLogo} alt="West Protocol Logo" className="logo-img" />
        <span className="logo-text">
          WEST <br /> <span className="protocol">PROTOCOL</span>
        </span>
      </div>

      {/* Бургер-меню */}
      <div className={`burger-menu-btn ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
      </div>

      {/* Навигация и авторизация */}
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <ul>
          <li onClick={toggleMenu}>
            <Link to="/" className={isActive('/')}>
              Home
            </Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/about" className={isActive('/about')}>
              About
            </Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/security" className={isActive('/security')}>
              Security
            </Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/bounty" className={isActive('/bounty')}>
              Bounty
            </Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/support" className={isActive('/support')}>
              Support
            </Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/blog" className={isActive('/blog')}>
              Blog
            </Link>
          </li>
        </ul>

        <div className={`auth-buttons ${isOpen ? 'show' : ''}`}>
          {/* Кнопка с языками и выпадающий список */}
          <div className="lang-dropdown">
            <button className="lang-btn" onClick={toggleLangMenu}>
              <i className="flag-icon" /> {selectedLang}{' '}
              <i className={`row-icon ${isLangOpen ? 'open' : ''}`} />
            </button>
            {isLangOpen && (
              <ul className="lang-menu">
                <li onClick={() => selectLanguage('EN')}>
                  <i className="flag-icon" />
                  EN
                </li>
                <li onClick={() => selectLanguage('FR')}>
                  <i className="flag-icon" />
                  FR
                </li>
                <li onClick={() => selectLanguage('DE')}>
                  <i className="flag-icon" />
                  DE
                </li>
                <li onClick={() => selectLanguage('ES')}>
                  <i className="flag-icon" />
                  ES
                </li>
              </ul>
            )}
          </div>
          <Link className="signup-btn" to="/SingUp">
            Sign Up
          </Link>
          <Link className="login-btn" to="/LogIn">
            Log In
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
