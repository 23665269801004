import React, { useEffect } from 'react';
import Tooltip from './../Question/Tooltip';

function Partners() {
  useEffect(() => {
    document.title = 'Partners';
  }, []);

  return (
    <div className="main-block-container">
      <h2 className="main-block-title">Partners</h2>
      <div className="grid">
        <div className="block-grid-item block-with-segments">
          <div className="block-grid-item-header">
            <h3 className="block-grid-item-title">All profit from partners:</h3>
            <Tooltip />
          </div>
          <p className="profit-amount">$300</p>

          <div className="referrals-grid">
            <div className="referral-box">
              <div className="block-grid-item-header">
                <h3 className="block-grid-item-title">Active partners</h3>
                <Tooltip />
              </div>
              <span>$300</span>
            </div>
            <div className="referral-box">
              <div className="block-grid-item-header">
                <h3 className="block-grid-item-title">Referrals turnover:</h3>
                <Tooltip />
              </div>
              <span>$300</span>
            </div>
          </div>

          <div className="referral-link">
            <div className="block-grid-item-header">
              <h3 className="block-grid-item-title">Your referral link:</h3>
              <Tooltip />
            </div>
            <div className="referral-link-block">
              <input type="text" value="https://West.com/?ref=olegg99.00@mail.ru" readOnly />
              <button className="copy-btn">Copy</button>
            </div>
          </div>
        </div>

        <div className="block-grid-item active-deposits">
          <div className="table-header">
            <div className="table-title-block">
              <h3 className="table-title">Partners system details</h3>
            </div>
          </div>
          <div className="deposit-details">
            <div className="deposit-row">
              <div className="table-item-cell">
                <p className="table-item-cell-name">Level:</p>
                <p className="table-item-cell-value">1 Line</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Your bonus:</p>
                <p className="table-item-cell-value">7%</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Quantity:</p>
                <p className="table-item-cell-value">0</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Your profit received:</p>
                <p className="table-item-cell-value">$0</p>
              </div>
            </div>

            <div className="deposit-row">
              <div className="table-item-cell">
                <p className="table-item-cell-name">Level:</p>
                <p className="table-item-cell-value">2 Line</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Your bonus:</p>
                <p className="table-item-cell-value">2%</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Quantity:</p>
                <p className="table-item-cell-value">0</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Your profit received:</p>
                <p className="table-item-cell-value">$0</p>
              </div>
            </div>

            <div className="deposit-row">
              <div className="table-item-cell">
                <p className="table-item-cell-name">Level:</p>
                <p className="table-item-cell-value">3 Line</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Your bonus:</p>
                <p className="table-item-cell-value">1%</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Quantity:</p>
                <p className="table-item-cell-value">0</p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Your profit received:</p>
                <p className="table-item-cell-value">$0</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
