import React, { useEffect } from 'react';
import './Dashboard.css';
import Tooltip from './../Question/Tooltip';

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  return (
    <div className="main-block-container">
      <h2 className="main-block-title">Dashboard</h2>

      <div className="grid">
        <div className="block-grid-item block-with-segments">
          <div className="block-grid-item-header block-grid-item-header-top">
            <h3 className="block-grid-item-title">All time investment profit:</h3>
            <Tooltip />
          </div>
          <p className="profit-amount">$300</p>

          <div className="referrals-grid">
            <div className="referral-box">
              <div className="block-grid-item-header">
                <h3 className="block-grid-item-title">Profit from referrals:</h3>
                <Tooltip />
              </div>
              <span>$300</span>
            </div>
            <div className="referral-box">
              <div className="block-grid-item-header">
                <h3 className="block-grid-item-title">Active partners:</h3>
                <Tooltip />
              </div>
              <span>$300</span>
            </div>
          </div>

          <div className="referral-link">
            <div className="block-grid-item-header">
              <h3 className="block-grid-item-title">Your referral link:</h3>
              <Tooltip />
            </div>
            <div className="referral-link-block">
              <input type="text" value="https://West.com/?ref=refog095.00@mail.ru" readOnly />
              <button className="copy-btn">Copy</button>
            </div>
          </div>
        </div>

        <div className="block-grid-item active-deposits">
          <div className="table-header">
            <div className="table-title-block">
              <h3 className="table-title">Active deposits</h3>
              <p>$300</p>
            </div>
            <Tooltip />
          </div>
          <div className="deposit-details">
            <div className="deposit-row">
              <div className="table-item-cell">
                <p className="table-item-cell-name">Profit</p>
                <p className="table-item-cell-value">
                  1% <span>Daily</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Invested</p>
                <p className="table-item-cell-value">
                  2000.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Earned</p>
                <p className="table-item-cell-value Earned">
                  0.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Next accrual</p>
                <p className="table-item-cell-value">
                  <i className="green-dot"></i> 23 <span>H</span> 55 <span>M</span>
                </p>
              </div>
            </div>

            <div className="deposit-row">
              <div className="table-item-cell">
                <p className="table-item-cell-name">Profit</p>
                <p className="table-item-cell-value">
                  1% <span>Daily</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Invested</p>
                <p className="table-item-cell-value">
                  2000.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Earned</p>
                <p className="table-item-cell-value Earned">
                  0.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Next accrual</p>
                <p className="table-item-cell-value">
                  <i className="green-dot"></i> 23 <span>H</span> 55 <span>M</span>
                </p>
              </div>
            </div>

            <div className="deposit-row">
              <div className="table-item-cell">
                <p className="table-item-cell-name">Profit</p>
                <p className="table-item-cell-value">
                  1% <span>Daily</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Invested</p>
                <p className="table-item-cell-value">
                  2000.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Earned</p>
                <p className="table-item-cell-value Earned">
                  0.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Next accrual</p>
                <p className="table-item-cell-value">
                  <i className="green-dot"></i> 23 <span>H</span> 55 <span>M</span>
                </p>
              </div>
            </div>

            <div className="deposit-row">
              <div className="table-item-cell">
                <p className="table-item-cell-name">Profit</p>
                <p className="table-item-cell-value">
                  1% <span>Daily</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Invested</p>
                <p className="table-item-cell-value">
                  2000.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Earned</p>
                <p className="table-item-cell-value Earned">
                  0.00 <span>USDT</span>
                </p>
              </div>
              <div className="table-item-cell">
                <p className="table-item-cell-name">Next accrual</p>
                <p className="table-item-cell-value">
                  <i className="green-dot"></i> 23 <span>H</span> 55 <span>M</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="block-grid-item details-security">
          <h3 className="info-title">Details & Security</h3>
          <div className="info-text">
            <span>Your IP:</span> <span className="info-text-value">111.1.111.111</span>
          </div>
          <div className="info-text">
            <span>Last visit:</span> <span className="info-text-value">03.09.2024 00:01</span>
          </div>
          <div className="info-text">
            <span>Email:</span>
            <span className="info-text-value">solscan@westprotocol.com</span>
          </div>
        </div>

        <div className="block-grid-item details-security">
          <h3 className="info-title">Your finances</h3>
          <div className="info-text">
            <span>Available balance:</span>
            <span className="info-text-value">$300</span>
          </div>
          <div className="info-text">
            <span>Invested:</span>
            <span className="info-text-value">$300</span>
          </div>
          <div className="info-text">
            <span>From referrals:</span>
            <span className="info-text-value">$300</span>
          </div>
          <div className="info-text">
            <span>Earned:</span>
            <span className="info-text-value">$300</span>
          </div>
        </div>

        <div className="table">
          <h3>Your operations</h3>
          <div className="operations-table">
            <div className="table-row">
              <div className="table-row-cell">
                <span className="table-row-cell-name">ID</span>
                <span className="table-row-cell-value">549</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Type</span>
                <span className="table-row-cell-value">Withdraw</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Amount</span>
                <span className="table-row-cell-value">
                  2000.00 <span>USDT</span>
                </span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Payment</span>
                <span className="table-row-cell-value">TRC20</span>
              </div>
              <div className="table-row-cell pending-cell">
                <span className="table-row-cell-name">Status</span>
                <span className="table-row-cell-value">Pending</span>
              </div>
            </div>

            <div className="table-row">
              <div className="table-row-cell">
                <span className="table-row-cell-name">ID</span>
                <span className="table-row-cell-value">549</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Type</span>
                <span className="table-row-cell-value">Withdraw</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Amount</span>
                <span className="table-row-cell-value">
                  2000.00 <span>USDT</span>
                </span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Payment</span>
                <span className="table-row-cell-value">TRC20</span>
              </div>
              <div className="table-row-cell complete-cell">
                <span className="table-row-cell-name">Status</span>
                <span className="table-row-cell-value">Pending</span>
              </div>
            </div>

            <div className="table-row">
              <div className="table-row-cell">
                <span className="table-row-cell-name">ID</span>
                <span className="table-row-cell-value">549</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Type</span>
                <span className="table-row-cell-value">Withdraw</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Amount</span>
                <span className="table-row-cell-value">
                  2000.00 <span>USDT</span>
                </span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Payment</span>
                <span className="table-row-cell-value">TRC20</span>
              </div>
              <div className="table-row-cell complete-cell">
                <span className="table-row-cell-name">Status</span>
                <span className="table-row-cell-value">Pending</span>
              </div>
            </div>

            <div className="table-row">
              <div className="table-row-cell">
                <span className="table-row-cell-name">ID</span>
                <span className="table-row-cell-value">549</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Type</span>
                <span className="table-row-cell-value">Withdraw</span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Amount</span>
                <span className="table-row-cell-value">
                  2000.00 <span>USDT</span>
                </span>
              </div>
              <div className="table-row-cell">
                <span className="table-row-cell-name">Payment</span>
                <span className="table-row-cell-value">TRC20</span>
              </div>
              <div className="table-row-cell complete-cell">
                <span className="table-row-cell-name">Status</span>
                <span className="table-row-cell-value">Pending</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
