import React, { useState, useEffect, useRef } from 'react';
import './CommunityTrustSlider.css';
import CoinMarketCapLogo from './../../Images/CoinMarketCap.svg';
import BinanceLogo from './../../Images/Binance.svg';
import BlockchainReporterLogo from './../../Images/BlockchainReportet.svg';

const items = [
  {
    id: 1,
    img: CoinMarketCapLogo,
    text: 'West Protocol offers excellent opportunities for investors in MEV bots. The platform provides innovative solutions that significantly increase profitability. Integration with advanced algorithms and unparalleled security features make investments particularly attractive.',
  },
  {
    id: 2,
    img: BinanceLogo,
    text: 'Every investor should try West Protocol! MEV bots offer unique opportunities for users to earn money! West Protocol opens the door to the exciting world of decentralized finance. Their innovative solutions in the field of MEV bots guarantee safety and effectiveness for every investment.',
  },
  {
    id: 3,
    img: BlockchainReporterLogo,
    text: 'Every investor should try West Protocol! MEV bots are a unique way for the average user to earn! Here, there is an opportunity for everyone who wants to develop in the world of decentralized finance and blockchain technologies.',
  },
];

const CustomCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(1); // Индекс текущего слайда
  const [slideWidth, setSlideWidth] = useState(0); // Динамическая ширина слайда
  const [gap, setGap] = useState(0); // Расстояние между слайдами
  const carouselRef = useRef(null);
  const slideRef = useRef(null);
  const transitionTime = 300;
  const [currentPosition, setCurrentPosition] = useState();

  // Обновляем ширину слайда и вычисляем gap
  useEffect(() => {
    const updateSlideWidthAndGap = () => {
      if (slideRef.current && carouselRef.current) {
        const width = slideRef.current.offsetWidth;
        const computedStyles = window.getComputedStyle(carouselRef.current);
        const computedGap = parseInt(computedStyles.gap || computedStyles.columnGap, 10);

        setCurrentPosition(width);
        setSlideWidth(width);
        setGap(computedGap);
      }
    };

    updateSlideWidthAndGap();
    window.addEventListener('resize', updateSlideWidthAndGap);

    return () => window.removeEventListener('resize', updateSlideWidthAndGap);
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.style.transition = `transform ${transitionTime}ms ease`;
      const translateValue = -(currentIndex * (slideWidth + gap));
      carouselRef.current.style.transform = `translateX(${translateValue}px)`;
    }
  }, [currentIndex, slideWidth, gap]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex < items.length - 2 ? prevIndex + 1 : -1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : items.length - 2));
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <div className="carousel" ref={carouselRef}>
          <div key={items[items.length - 1].id} className="carousel-item" ref={slideRef}>
            <div
              style={{ backgroundImage: `url(${items[items.length - 1].img})` }}
              className="carousel-item-img"></div>
            <p>{items[items.length - 1].text}</p>
          </div>

          {items.map((item) => (
            <div key={item.id} className="carousel-item" ref={slideRef}>
              <div
                style={{ backgroundImage: `url(${item.img})` }}
                className="carousel-item-img"></div>
              <p>{item.text}</p>
            </div>
          ))}

          <div key={items[0].id} className="carousel-item" ref={slideRef}>
            <div
              style={{ backgroundImage: `url(${items[0].img})` }}
              className="carousel-item-img"></div>
            <p>{items[0].text}</p>
          </div>
        </div>
        <div className="carousel-button prev" onClick={prevSlide}></div>
        <div className="carousel-button next" onClick={nextSlide}></div>
      </div>
    </div>
  );
};

export default CustomCarousel;
