import React, { useEffect } from 'react';
import './Support.css';
import FAQItem from '../../FAQItem/FAQItem';

function Support() {
  const faqData = [
    {
      question: 'How to make a deposit for MEV bot operations?',
      answer:
        'To make a deposit, please click the "Sign Up" button and complete the registration process. Once registered, navigate to the "Investment" section and choose the investment plan that best suits your needs. Select the cryptocurrency you wish to invest in, specify the desired amount, and click "Invest" to complete the transaction.',
    },
    {
      question: 'Which cryptocurrencies do you accept?',
      answer:
        'We accept a wide range of popular cryptocurrencies, including Bitcoin, Ethereum, and more.',
    },
    {
      question: 'Deposit limits and quantity',
      answer:
        'The minimum deposit amount is $100. Funds will be credited depending on network conditions. If we detect that a user has more than one account, all funds will be held until further notice. There is no limit to the number of deposits you can make.',
    },
    {
      question: 'How are interest rates calculated on my account?',
      answer: 'Interest rates are calculated based on your selected investment plan.',
    },
    {
      question: 'How are interest rates calculated on my account?',
      answer: 'Interest rates are calculated based on your selected investment plan.',
    },
    {
      question: 'When can I withdraw my investment and profits?',
      answer:
        'We will process your withdrawal request as soon as possible, but please allow some time for the network to confirm the transaction. Your investment will be returned to you at the end of the investment period. You will receive your initial earnings exactly 24 hours after making the deposit. You can withdraw funds at any time. The time it takes to process a withdrawal depends on the cryptocurrency network, but there are no delays on our end. Typically, funds are credited to your wallet within a few minutes after the request is confirmed. The maximum processing time for a withdrawal is 24 hours.',
    },
    {
      question: 'What if I cant log into my account because I forgot my password?',
      answer:
        'If you forget your password, click the "Forgot Password" button and enter your username or email address. You will receive a password reset email.',
    },
    {
      question: 'Who manages the funds and what are the guarantees?',
      answer:
        'Funds are managed by an automated algorithm of the MEV Bot network. We are a licensed and registered company, our activities are fully legal, and we have all the necessary licenses and permissions. Our reputation has been validated by more than 5,000 satisfied investors, and we receive numerous positive reviews. We are regularly featured in the media.',
    },
    {
      question: 'Is it possible to use the WEST PROTOCOL platform on mobile devices?',
      answer:
        'Yes, the Unimatic Protocol platform is available for use on mobile devices. We have optimized the site for easy access to your account and management anytime, anywhere.',
    },
  ];

  useEffect(() => {
    document.title = 'Support';
  }, []);

  return (
    <div className="page">
      <section className="home-section contact-section delete-border-radius">
        <div className="home-section-container contact-section-container">
          <div class="contact-block contact-form">
            <form>
              <h3>Contact us</h3>
              <div className="inputs">
                <input type="email" placeholder="E-mail" required />
                <input type="text" placeholder="Subject" required />
              </div>
              <textarea placeholder="Letter" required></textarea>
              <button type="submit" class="send-message">
                Send message <i></i>
              </button>
            </form>
          </div>

          <div className="contact-blocks">
            <div class="contact-block telegram-block">
              <h3>Telegram Channel</h3>
              <p>News, Promo and Social networking.</p>
            </div>

            <div class="contact-block community-block">
              <h3>Community Chat</h3>
              <p>Connect with our society.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="FAQ-section FAQ-special">
        <h2>FAQ</h2>
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </section>
    </div>
  );
}

export default Support;
