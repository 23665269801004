import React, { useEffect, useState } from 'react';
import './Invest.css'; // Ваши стили
import { useSelector, useDispatch } from 'react-redux';
import { setInvestData } from './../../../Redux/Slices/InvestSlice';

import Token1 from './../../../Images/Token1.svg';
import Token2 from './../../../Images/Token2.svg';
import Token3 from './../../../Images/Token3.svg';
import Token4 from './../../../Images/Token4.svg';
import Token5 from './../../../Images/Token5.svg';
import { useNavigate } from 'react-router-dom';

const Invest = () => {
  const [selectedPlan, setSelectedPlan] = useState('Sniper Bot');
  const [selectedCurrency, setSelectedCurrency] = useState({
    currency: 'BTC',
    name: 'Bitcoin',
    logo: Token2,
  });
  const [amount, setAmount] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Invest';
  }, []);

  const investClick = () => {
    const investData = {
      currency: selectedCurrency.currency,
      name: selectedCurrency.name,
      logo: selectedCurrency.logo,
      amount: amount,
      plan: selectedPlan,
    };

    dispatch(setInvestData(investData));
    navigate('/AddFunds');
  };

  return (
    <div className="invest-container">
      <h1 className="main-block-title main-block-title-invest">Invest</h1>

      <div className="invest-step">
        <div className="invest-text-block">
          <h2 className="invest-step-title">
            <span className="step-number">01</span>
            <span className="invest-step-title-name">
              <span className="invest-step-title-name-top">Select</span>
              <span className="invest-step-title-name-bottom">Earn Method</span>
            </span>
          </h2>
          <div className="invest-step-description">
            <ul>
              <li>
                Each invest plan has different monthly income, as well as different min and max
                amount of funds you can invest.
              </li>
              <li>
                You will receive your first profit exactly 24 hours after the deposit. Daily profits
                are available for withdrawal every day.
              </li>
              <li>
                Regardless of the selected group, the investment period in all cases will be equal
                to 180 days. After the period, you will be able to withdraw or reinvest your initial
                deposit.
              </li>
            </ul>
          </div>
        </div>
        <div className="invest-plans">
          <div
            className={`invest-plan ${selectedPlan === 'Sniper Bot' ? 'active' : ''}`}
            onClick={() => setSelectedPlan('Sniper Bot')}>
            <h3 className="plan-title">SNIPER BOT</h3>
            <p className="plan-percentage">
              +1% <span>/daily</span>
            </p>
            <p className="plan-term">
              Term: <div className="line"></div>
              <span>180 DAYS</span>
            </p>
            <p className="plan-accruals">
              Accruals:<div className="line"></div> <span>DAILY</span>
            </p>
            <p className="plan-min-deposit">
              Min. deposit: <div className="line"></div> <span>$100</span>
            </p>
            <p className="plan-max-deposit">
              Max. deposit: <div className="line"></div> <span>$2,999</span>
            </p>
            <div className="plan-indicator active"></div>
          </div>
          <div
            className={`invest-plan ${selectedPlan === 'Uni Botnet' ? 'active' : ''}`}
            onClick={() => setSelectedPlan('Uni Botnet')}>
            <h3 className="plan-title">UNI BOTNET</h3>
            <p className="plan-percentage">
              +2% <span>/daily</span>
            </p>
            <p className="plan-term">
              Term: <div className="line"></div> <span>180 DAYS</span>
            </p>
            <p className="plan-accruals">
              Accruals: <div className="line"></div> <span>DAILY</span>
            </p>
            <p className="plan-min-deposit">
              Min. deposit: <div className="line"></div> <span>$3,000</span>
            </p>
            <p className="plan-max-deposit">
              Max. deposit: <div className="line"></div> <span>$9,999</span>
            </p>
            <div className="plan-indicator"></div>
          </div>
          <div
            className={`invest-plan ${selectedPlan === 'Multi Chain' ? 'active' : ''}`}
            onClick={() => setSelectedPlan('Multi Chain')}>
            <h3 className="plan-title">MULTI CHAIN</h3>
            <p className="plan-percentage">
              +3% <span>/daily</span>
            </p>
            <p className="plan-term">
              Term: <div className="line"></div> <span>180 DAYS</span>
            </p>
            <p className="plan-accruals">
              Accruals: <div className="line"></div> <span>DAILY</span>
            </p>
            <p className="plan-min-deposit">
              Min. deposit: <div className="line"></div> <span>$10,000</span>
            </p>
            <p className="plan-max-deposit">
              Max. deposit: <div className="line"></div> <span>$29,999</span>
            </p>
            <div className="plan-indicator"></div>
          </div>
          <div
            className={`invest-plan ${selectedPlan === 'VIP+' ? 'active' : ''}`}
            onClick={() => setSelectedPlan('VIP+')}>
            <h3 className="plan-title">VIP+</h3>
            <p className="plan-percentage">
              +5% <span>/daily</span>
            </p>
            <p className="plan-term">
              Term: <div className="line"></div>
              <span>180 DAYS</span>
            </p>
            <p className="plan-accruals">
              Accruals: <div className="line"></div> <span>DAILY</span>
            </p>
            <p className="plan-min-deposit">
              Min. deposit: <div className="line"></div> <span>$30,000</span>
            </p>
            <p className="plan-max-deposit">
              Max. deposit: <div className="line"></div> <span>$999,999</span>
            </p>
            <div className="vip-indicator">
              <span className="vip-indicator-text">2 Left</span>
              <div className="plan-indicator plan-indicator-vip"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-steps">
        <div className="invest-step">
          <h2 className="invest-step-title">
            <span className="step-number">02</span>
            <span className="invest-step-title-name">
              <span className="invest-step-title-name-top">Payment</span>
              <span className="invest-step-title-name-bottom">Currency</span>
            </span>
          </h2>
          <div className="currency-options">
            {[
              { currency: 'BTC', name: 'Bitcoin', logo: Token2 },
              { currency: 'ETH', name: 'Etherium', logo: Token3 },
              { currency: 'USDT', name: 'TRX20', logo: Token4 },
              { currency: 'USDC', name: 'ERC20', logo: Token1 },
              { currency: 'BTC1', name: 'Bitcoin', logo: Token2 },
              { currency: 'ETH1', name: 'Etherium', logo: Token3 },
              { currency: 'USDT1', name: 'TRX20', logo: Token4 },
              { currency: 'USDC1', name: 'ERC20', logo: Token1 },
            ].map(({ currency, name, logo }) => (
              <div
                className={`currency-option ${
                  selectedCurrency.currency === currency ? 'active-currency' : ''
                }`}
                onClick={() => setSelectedCurrency({ currency, name, logo })}
                key={currency}>
                <img src={logo} alt="" />
                <p className="currency-option-text ">
                  {currency} <span>{name}</span>
                </p>
              </div>
            ))}
          </div>
          <p className="selected-currency">
            SELECTED:
            <span>
              {' '}
              {selectedCurrency?.name} {selectedCurrency?.currency}
            </span>
          </p>
        </div>

        <div className="invest-step">
          <h2 className="invest-step-title">
            <span className="step-number">03</span>
            <span className="invest-step-title-name">
              <span className="invest-step-title-name-top">Enter</span>
              <span className="invest-step-title-name-bottom">Amount</span>
            </span>
          </h2>
          <div className="amount-input">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
            />
            <span className="currency-label">
              {selectedCurrency?.currency} <img src={selectedCurrency?.logo} alt="" />
            </span>
          </div>
          <div className="amount-details">
            <p>
              <span>Max amount:</span> 6.6346 BTC
            </p>
            <p>
              <span>Min amount:</span> 0.0001 BTC
            </p>
          </div>
          <div class="profit-block">
            <div class="profit-item">
              <p class="profit-label">Deposit in USD</p>
              <p class="profit-value">$0.00</p>
            </div>
            <div class="profit-item">
              <p class="profit-label">Daily profit</p>
              <p class="profit-value">
                0.0000<span class="btc-label">BTC</span>
              </p>
            </div>
            <div class="profit-item">
              <p class="profit-label">Monthly profit</p>
              <p class="profit-value">
                0.0000<span class="btc-label">BTC</span>
              </p>
            </div>
            <div class="profit-item">
              <p class="profit-label">Yearly profit</p>
              <p class="profit-value">
                0.0000<span class="btc-label">BTC</span>
              </p>
            </div>
          </div>
          <button className="invest-btn" onClick={investClick}>
            Invest <i></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invest;
