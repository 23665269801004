import React, { useEffect } from 'react';

function WithdrawDetails() {
  useEffect(() => {
    document.title = 'Withdraw';
  }, []);

  return (
    <div>
      <div className="main-block-container">
        <h2 className="main-block-title">Withdraw</h2>

        <div className="block-grid-item details-security">
          <h3 className="info-title">Operation Details</h3>
          <div className="info-text">
            <span>Operation Type:</span> <span className="info-text-value">Withdraw</span>
          </div>
          <div className="info-text">
            <span>Status:</span> <span className="info-text-value">Add Funds</span>
          </div>
          <div className="info-text">
            <span>Date:</span>
            <span className="info-text-value">15.06.2024</span>
          </div>
          <div className="info-text">
            <span>Amount:</span>
            <span className="info-text-value">0.001 ETH</span>
          </div>
          <div className="info-text">
            <span>Network:</span>
            <span className="info-text-value">ETH</span>
          </div>
          <div className="info-text">
            <span>Payee address:</span>
            <span className="info-text-value address-text-value">
              svghjkvabdcsbfvalhjbsdcdnlbvsdalksbvlsdblj,cn
            </span>
          </div>
          <div className="info-text info-text-buttons">
            <button className="Withdraw-btn cansel-btn">Cansel</button>
            <button className="Withdraw-btn confirm-btn">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawDetails;
