import React, { useEffect } from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  useEffect(() => {
    document.title = 'Privacy Policy';
  }, []);

  return (
    <div className="page privacy-policy-section">
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <div className="privacy-policy-container">
        <div className="privacy-section">
          <h2 className="privacy-heading">1. GENERAL</h2>
          <p className="privacy-text">
            This is the Privacy Policy (hereafter referred to as the "Policy") of West LLC, a
            company incorporated in USA with registration number 21538239890 and located at office
            street address 675 15th Street, Denver, CO 80202, US.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">2. OUR COMMITMENT</h2>
          <p className="privacy-text">
            Your privacy is of utmost importance to us, and it is our priority to safeguard and
            respect the confidentiality of your information, your privacy, and your rights. By
            entrusting us with your information, we would like to assure you of our commitment to
            keep such information private. We have taken measurable steps to protect the
            confidentiality, security and integrity of your personal information as illustrated
            herein.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">3. PURPOSE OF THIS POLICY</h2>
          <p className="privacy-text">
            This Policy sets out how West collects and processes your personal data via using West
            official website West.com, including any data you may provide through this website when
            you obtain our service as per the Client Agreement. Additionally, it provides
            information on how you can exercise your rights with respect to the processing of your
            personal data.
          </p>
          <p className="privacy-text">
            This Policy applies to the processing activities performed by West to the personal data
            of its clients and potential clients, website visitors and employees as in order to
            provide our products and services to clients, performing our daily business operations,
            we need to collect from them personal information. This website is not intended for
            children and we do not knowingly collect data relating to children.
          </p>
          <p className="privacy-text">
            You are strongly advised to read this Policy carefully, as it is part of the Company’s
            Terms and Conditions, Terms of Use and/or Business as applicable and shows how we comply
            with the General Data Protection Regulation of the American Union (the “GDPR”) and other
            related laws and regulations.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">4. WHO WE ARE?</h2>
          <p className="privacy-text">
            West LLC is the Data Protection Officer ("DPO") of the Company, responsible for
            overseeing questions relating to this Policy and any other related matters...
          </p>
          <p className="privacy-text">
            Entity: West LLC Contact Person: Data Protection Officer
            <br />
            Email address: support@West.com
            <br />
            Postal address: 675 15th Street, Denver, CO 80202, US
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">5. AMENDMENTS</h2>
          <p className="privacy-text">
            The Company reserves the right to amend this document from time to time and we will
            notify you appropriately and accordingly. We do however encourage you to review this
            statement periodically to always be informed about how we are processing and protecting
            your personal information and contact our DPO at dpo@West.com if you have any concerns.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">6. WHAT PERSONAL DATA WE COLLECT ABOUT YOU?</h2>
          <p className="privacy-text">
            The Company will collect, use, store and transfer different personal data for the
            purposes of providing our services to you and ensure that we comply with our legal
            obligations. Therefore, if you fail to provide us with some information we may request,
            it is likely that we may not be able to provide you with our products and/or services.
          </p>
          <p className="privacy-text">
            The list below includes the type of personal data we may need from you:
          </p>
          <ul className="privacy-list">
            <li>
              Personal data for identity purposes such as name, maiden name, last name, proof of
              identity, username or similar identifier, marital status, title, date and place of
              birth and gender, country of residence and citizenship.
            </li>
            <li>
              Personal data for contact purposes such as billing address, delivery address, email
              address and telephone numbers, proof of address.
            </li>
            <li>
              Personal data for professional purposes such as the level of education, profession,
              employer name, work experience in Forex/CFD’s, information on Client’s experience,
              knowledge in forex industry and risks.
            </li>
            <li>
              Personal data for tax and financial purposes such as the country of tax residence and
              tax identification number, your annual income, net worth, source of funds, anticipated
              account turnover, bank account, bank statements, payment card details and copies,
              e-wallet information.
            </li>
            <li>
              Transaction data such as details about payments to and from you and other details of
              products and services you have purchased from us, deposit methods, purpose of
              transactions with us.
            </li>
            <li>
              Technical data such as internet protocol (IP) address, your login data, browser type
              and version, time zone setting and location, browser plug-in types and versions,
              operating system and platform and other technology on the devices you use to access
              this website or data regarding the usage of our website, products and services.
            </li>
            <li>
              Profile data such as username and password, purchases or orders made by you, your
              interests, preferences, feedback and survey responses and marketing and communication
              data such as your preferences in receiving marketing from us and our third parties and
              your communication preferences.
            </li>
          </ul>
          <p className="privacy-text">
            The Company may also process sensitive personal information such as information about
            criminal convictions and offences for employment purposes to the extent permitted by
            laws and required for the performance of our legal obligations.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">7. HOW WE COLLECT YOUR DATA?</h2>
          <p className="privacy-text">
            The Company may collect your personal data via different methods and therefore, process
            5. Process the personal data you provide us directly and/or collect information as a
            result of automated technologies, interactions, and/or from third parties via publicly
            available sources. Specifically, information you provide to us directly may include your
            identity, contact and financial information by registering for an account with West,
            when you participate in any interactive features of the services we provide, when you
            fill out a form we provide you with, when you participate in any promotion or contest
            powered by West, when you apply for a job, communicate with us, request customer support
            or otherwise contact us. As a result of using our website, we may automatically collect
            technical data about your equipment, browsing actions and patterns. <br /> <br /> As per
            our Cookie Policy, we collect this personal data by using cookies, and other similar
            technologies. We may also receive Technical Data about you if you visit other websites
            employing our cookies. <br /> <br />
            Finally, personal data may be collected, such as technical data from analytics providers
            such as Google, based outside of the US, contact, financial and transaction data from
            providers of payment services based inside the US and identity and contact data from
            individual and publicly available sources, such as Registrar of Companies based inside
            US and Thomson Reuters based outside of US.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">8. DISCLOSURE OF YOUR PERSONAL DATA</h2>
          <p className="privacy-text">
            The Company may disclose your personal information for the purposes of processing
            transactions and providing services relating to your account to any West affiliated
            Company operating domestically or abroad that the client may elect to seek services from
            and which falls within the West trading or West payment environment. Such sharing of
            data between the affiliated Companies includes and is not limited to the data and
            documents collected by the Company for identity purposes. The Company may also share
            your personal data with services providers in the areas of IT, Trading platforms
            administration, translation of documents, marketing, external and internal audit,
            payment service providers and banks.
            <br /> <br /> Where it is required to disclose your personal data to third parties for
            the purposes of 6. Performing our legal obligations towards you, we ensure that the
            relevant provisions of GDPR are respected. Specifically, we assess these third parties
            in respect of their compliance with the data protection laws and regulations and ensure
            that required contractual agreements are concluded. Respectively, we ensure that they
            are committed to respect the security of your information and treat it in accordance
            with relevant laws and regulations. Finally, we do not allow our third-party service
            providers to use your personal data for their own purposes and only permit them to
            process your personal data for specified purposes and in accordance with our
            instructions.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">9. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</h2>
          <p className="privacy-text">
            Some of our external third parties are based outside the American Economic Area (“EEA”)
            so their processing of your personal data will involve a transfer of data outside the
            EEA. Please be informed that, whenever we transfer your personal data out of the EEA, we
            ensure a similar degree of protection is afforded to it by ensuring at least one of the
            following safeguards is implemented:
          </p>
          <p className="privacy-text">
            we will only transfer your personal data to countries that have been deemed to provide
            an adequate level of protection for personal data by the American Commission.
          </p>
          <ul className="privacy-list">
            <li>we assess the business and</li>
            <li>
              where we use certain service providers, we use specific contracts approved by the
              American Commission which give personal data the same protection it has in US.
            </li>
            <li>
              where we use providers based in the US, we may transfer data to them if they are part
              of the Privacy Shield which requires them to provide similar protection to personal
              data shared between the Europe and the US.
            </li>
          </ul>
          <p className="privacy-text">
            Should you need further clarifications on this, please contact our DPO at
            support@West.com
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">10. HOW WE USE YOUR PERSONAL DATA?</h2>
          <p className="privacy-text">
            The Company may use, store, process and handle your personal data in various ways and
            for that rely on specific legal bases. Please be informed however, that we may process
            your personal data for more than one lawful ground and depending on the specific purpose
            for which we will use them. The purposes for which we may process your personal data are
            exhibited below in more detail. <br />
            <br /> THE FRAMEWORK OF THE CLIENT AGREEMENT: we process personal data to provide our
            services and products, as well as information regarding these services and products,
            based on the contractual relationship with you. We need, therefore, to use your personal
            information to perform our services and comply with our obligations to you. In this
            framework, we need to verify your identity to accept you as our client, and we will need
            to use those personal details to effectively manage your trading account with us.
            <br /> <br /> COMPLIANCE WITH LAWS AND REGULATIONS: numerous laws to which we are
            subject, as well as specific statutory requirements (such as anti-money laundering laws,
            financial services laws, corporation laws, privacy laws and tax laws) dictate that we
            hold and process personal data. Such obligations and requirements impose on us necessary
            personal data storage and processing activities. It is mandatory for example, to store
            personal data, for record keeping purposes. In general, complying with applicable laws,
            court orders, other judicial process, or the requirements of any applicable regulatory
            authorities may require the processing of personal data by West LLC. <br />
            <br /> SAFEGUARDING OUR LEGITIMATE INTERESTS: we process personal data to safeguard the
            legitimate interests pursued by West. Examples of such processing activities include our
            initiating legal claims and preparing our defence in litigation procedures and
            processing personal data for risk management purposes. Further, we may need to use
            personal information collected from you to investigate issues or to settle disputes with
            you because it is in our legitimate interests to ensure that issues and disputes get
            investigated and resolved in a timely and efficient manner.
            <br /> <br /> CASES YOU HAVE CONSENTED TO: our storage and use of your personal data are
            based on your consent provided by opening an account with West. Please be informed that,
            if we rely on your consent as our legal basis for holding and processing your personal
            information, you have the right to revoke consent at any time, by contacting our DPO at
            West.com. However, any processing of personal data that took place prior to the receipt
            of your revocation will not be affected. If you have consented to be contacted by us for
            marketing purposes, we may use your personal information to send you marketing
            communications by email or phone or other agreed forms to ensure that you are kept up to
            date with our latest products and services. We will not disclose your information to any
            outside parties for them to directly market to you. <br />
            <br /> TO ASSESS THE APPROPRIATENESS AND SUITABILITY OF OUR SERVICES TO THE RECIPIENTS:
            your personal data, which includes your knowledge and experience in the financial
            products that we offer, as well as your investment objectives including your risk
            tolerance and financial situation, is used to form your economic profile and confirm our
            assessment of the degree to which such financial products are appropriate to you. <br />
            <br /> TO IMPROVE OUR PRODUCTS AND SERVICES: from time to time, we may send you surveys
            as part of our customer feedback process. It is in our legitimate interest to ask for
            such feedback to try to ensure that we provide our services and products at the highest
            standards. We may use the personal information provided by you through such client
            surveys to help us improve our products and services. <br />
            <br /> LEGAL NOTIFICATIONS: laws and regulations occasionally require that we advise you
            of certain changes to products or services or laws. We may need to inform you of changes
            to the terms or the features of our products or services and doing so implies that we
            will maintain and use your personal data.
            <br />
            <br />
            AGREEMENT BETWEEN US: we have the right to disclose your Information (including
            recordings and documents of a confidential nature, card details) in certain
            circumstances including but not limited to where required by law or a court order by a
            competent Court, 9 Privacy Policy our regulator Cyprus Securities and Exchange
            Commission or any other regulatory authority having control or jurisdiction over us or
            you or our associates or in whose territory we have clients or service providers and as
            applicable. If to provide services to you the Company discloses your personal
            information to business parties such as card or other payment processing companies or
            banks, such third parties may store your information to comply with their own legal and
            other obligations. The Company will notify the third party regarding the confidential
            nature of any such information.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">11. TECHNICAL AND OPERATIONAL SECURITY MEASURES</h2>
          <p className="privacy-text">
            The Company has adopted practical controls and security measures to ensure high level of
            information security and compliance with the relevant provisions of GDPR. Indeed, our
            administrative, physical, and technical safeguards that are implemented and maintained,
            protect your personal data from accidental or unlawful destruction, loss, alteration,
            unauthorized disclosure of, or access to personal data transmitted, sorted, or otherwise
            processed. Employees are authorized to access personal data only to the extent necessary
            to serve the applicable legitimate purposes for which the personal data are processed by
            the Company and to perform their job.
            <br /> <br /> Details of these measures may be available upon request at dpo@West.com
            and to the discretion of the DPO.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">12. AUTOMATED DECISION-MAKING AND PROFILING</h2>
          <p className="privacy-text">
            The Company is required to assess your knowledge and experience, your financial
            situation and investment objectives and your risk profile once per calendar year in
            accordance with relevant laws and regulations. This action is in accordance with the
            Directive 2014/65/EU MIFID and related Circulars and guidelines on this matter by the
            Cyprus Securities and Exchange Commission. The tools used on the above include the
            Appropriate Test and the Suitability Test as further discussed next.
            <br /> <br /> The Appropriateness Test takes place once you register as a client at West
            where we need to check and ensure that you are suitable for the provision of services
            and products we offer. This test is regarding your knowledge, financial background and
            experience with financial services and based on the scoring you receive, you will be
            informed whether you are eligible to receive our services and become our client and the
            maximum level of leverage you are eligible to. This ensures that our services and
            products are suitable to you and to the best of our interest.
            <br /> <br /> The Suitability Test takes place for the purposes of our investment
            program and for that we will assess your financial situation, risk profile and risk
            tolerance as an investor and that the investment program is suitable for you. Based on
            your scoring, we will be able to assess your investment objectives, if you are able
            financially to bear any related investment risks consistent with your investment
            objectives. <br />
            <br /> We ensure you that West takes all the technical and operational measures to
            correct inaccuracies and minimize the risk of errors, to prevent any discrimination and
            to secure personal data of the client. <br />
            <br /> The scorings above are monitored by the Compliance department of West and should
            you need any clarification, please contact us at compliance@West.io.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">13. COMMUNICATION</h2>
          <p className="privacy-text">
            The Company will record, monitor and process any telephone conversations and/or
            electronic communications between us such as through phone, fax, email, social media,
            electronic message between you and West. All such communications are recorded and/or
            monitored and/or processed by us, including but not limited to any telephone
            conversations and/or electronic communications that result or may result in transactions
            or client order services even if those conversations or communications do not result in
            the conclusion of such transactions. All incoming and outgoing telephone conversations
            as well as other electronic communications between you and West will be recorded and
            stored for quality monitoring, training and regulatory purposes. The content of relevant
            in person conversations and/or communications with you may be recorded by minutes or
            notes. Any such records 11 shall be provided to you upon request at the same language as
            the one used to provide investment services to you.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">14. STORAGE AND RETENTION OF YOUR PERSONAL DATA</h2>
          <p className="privacy-text">
            West holds your personal information on secure servers and appropriate procedures and
            measures are in place to ensure that your personal data is safeguarded while this is of
            utmost importance to us. We will hold your personal information while we have business
            relationship with you and as permitted by relevant laws and regulations. The retention
            of your personal data is limited for the purposes we collected it for and for satisfying
            any legal, accounting, or reporting requirements. To determine the appropriate retention
            period for personal data, in accordance with the provisions of GDPR, we consider various
            factors including, but not limited to, the amount, nature, and sensitivity of the
            personal data, and the potential risk of harm from unauthorised use or disclosure of
            your personal data. Moreover when we consider that personal information is no longer
            necessary for the purpose for which it was collected, we will remove any details that
            will identify you or we will securely destroy the records. However, we may need to
            maintain records for a significant period of time. For example, we are subject to
            certain anti-money laundering laws which require us to retain the following, for a
            period of five (5) years after our business relationship with you has ended:
          </p>
          <ul className="privacy-list">
            <li>
              a copy of the documents we used in order to comply with our customer due diligence
              obligations;
            </li>
            <li>
              supporting evidence and records of transactions with you and your relationship with
              us.
            </li>
          </ul>
          <p className="privacy-text">
            Also, the personal information we hold in the form of a recorded communication, by
            telephone, electronically, in person or otherwise, will be held in line with local
            regulatory requirements (i.e., 5 years after our business relationship with you has
            ended or longer if you have legitimate interests (such as handling a dispute with you).
            If you have opted out of receiving marketing communications we will hold your details on
            our suppression list so that we know you do not want to receive these communications. We
            may keep your data for longer than 5 years if we cannot delete it for legal, regulatory
            or technical reasons.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">15. WHAT ARE YOUR RIGHTS AS A DATA SUBJECT?</h2>
          <p className="privacy-text">
            You must be aware that GDPR is recognising you as a “Data subject” and you have certain
            rights which you can exercise freely and to your own discretion, as per below: Request
            access to your personal data: this enables you to receive a copy of the personal
            information we hold about you and to check that we are lawfully processing it.
          </p>
          <ul className="privacy-list">
            <li>
              Request correction of the personal information that we hold about you: this enables
              you to have any incomplete or inaccurate information we hold about you corrected.
            </li>
            <li>
              Request erasure of your personal data: this enables you to ask us to delete or remove
              personal data where there is no good reason for us to continue processing it. You also
              have the right to ask us to delete or remove your personal data where you have
              successfully exercised your right to object to processing (see below), where we may
              have processed your information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may not always be able
              to comply with your request of erasure for specific legal reasons which will be
              notified to you, if applicable, at the time of your request.
            </li>
            <li>
              Object to processing of your personal data where we are relying on a legitimate
              interest (or those of a third party) and there is something about your particular
              situation which makes you want to object to processing on this ground as you feel it
              impacts on your fundamental rights and freedoms. You also have the right to object if
              we are processing your personal data for direct marketing purposes. In some cases, we
              may demonstrate that we have compelling legitimate grounds to process your information
              which override your rights and freedoms.
            </li>
            <li>
              Object to processing of your personal data where we are relying on a legitimate
              interest (or those of a third party) and there is something about your particular
              situation which makes you want to object to processing on this ground as you feel it
              impacts on your fundamental rights and freedoms. You also have the right to object if
              we are processing your personal data for direct marketing purposes. In some cases, we
              may demonstrate that we have compelling legitimate grounds to process your information
              which override your rights and freedoms.
            </li>
            <li>
              Request the transfer of your personal data to you or to a third party: we will provide
              to you, or a third party you have chosen, your personal data in a structured, commonly
              used, machine- readable format. Note that this right only applies to automated
              information which you initially provided consent for us to use or where we have used
              the information to perform a contract with you.
            </li>
            <li>
              Right to withdraw consent: where we are relying on consent to process your personal
              data. However, this will not affect the lawfulness of any processing carried out
              before you withdraw your consent.
            </li>
          </ul>
          <p className="privacy-text">
            If you withdraw your consent, we may not be able to provide certain products or services
            to you. We will advise you if this is the case at the time you withdraw your consent. If
            you wish to exercise any of the above, you must send an email to the DPO of West at
            support@West.com, and your request will be further handled. Please note that as per our
            procedures, you will be asked to complete our Data Subject Request form in order to
            verify your request. <br />
            Please be informed the below:
          </p>
          <ul className="privacy-list">
            <li>
              a. usually no fee is required in order to exercise your rights, but we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or excessive or refuse
              to comply with your request in these circumstances
            </li>
            <li>
              a. usually no fee is required in order to exercise your rights, but we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or excessive or refuse
              to comply with your request in these circumstances
            </li>
            <li>
              c. we will always respond to your request within reasonable time and keep you updated.
            </li>
          </ul>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">16. PERSONAL DATA BREACH</h2>
          <p className="privacy-text">
            We have put in place appropriate procedures in order to deal with any breach of personal
            data and will notify you and any applicable regulator of a breach where we are legally
            required to do so.
            <br /> <br /> If you require further information on how we deal with a data breach,
            please contact our DPO at support@West.com.
          </p>
        </div>
      </div>

      <h1 className="privacy-policy-title">Cookie Policy</h1>
      <div className="privacy-policy-container">
        <div className="privacy-section">
          <p className="privacy-text">
            By visiting https://West.com/ and any subdomains of (hereafter collectively referred to
            as the “West website”), you are accepting and consenting to the practice described in
            this Cookie Policy (hereafter referred to as the “Policy”) of West LLC. The Company is
            committed to provide its customers with the best customized browsing experience while
            safeguarding each person’s personal data and privacy and in accordance with the
            Company’s Privacy Policy.
          </p>
          <h2 className="privacy-heading">What are cookies?</h2>
          <p className="privacy-text">
            Cookies are small text files which a website may put on your computer or mobile device
            when you first visit such website. The cookie will help the website recognize your
            device the next time you visit and to analyse your behavior in order to improve your
            experience. Simply the cookies allow us to understand what content is most relevant to
            you and provide you with more relevant information. This does not mean however that we
            use them to determine the personal identity of anyone merely by visiting the West
            website.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">What kind of cookies do we use?</h2>
          <ul className="privacy-list">
            <li>
              Necessary cookies, which help make a website usable by enabling basic functions like
              page navigation and access to secure areas of the website. The website cannot function
              properly without these cookies.
            </li>
            <li>
              Preference cookies which enable a website to remember information that changes the way
              the website behaves or looks, like your preferred language or the region that you are
              in.
            </li>
            <li>
              Statistical cookies which help website owners to understand how visitors interact with
              websites by collecting and reporting information anonymously.
            </li>
            <li>
              Marketing cookies, which are used to track visitors across websites. The intention is
              to display ads that are relevant and engaging for the individual user and thereby more
              valuable for publishers and third-party advertisers.
            </li>
            <li>
              Unclassified cookies which are in the process of being classified, together with the
              providers of individual cookies, by the Company.
            </li>
          </ul>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">How to control cookies</h2>
          <p className="privacy-text">
            You can control the cookies we store in the pop-up which appears on your first visit our
            website. Please note that you do not have to accept cookies, but if you turn them off or
            delete them, the West Website may not perform as it has been designed to, possibly
            reducing the functionality on the Website, the options available to you, as well as the
            performance of the Website during your visit.You can always control the cookies we store
            via changing the website settings.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">15. WHAT ARE YOUR RIGHTS AS A DATA SUBJECT?</h2>
          <p className="privacy-text">
            You must be aware that GDPR is recognising you as a “Data subject” and you have certain
            rights which you can exercise freely and to your own discretion, as per below: Request
            access to your personal data: this enables you to receive a copy of the personal
            information we hold about you and to check that we are lawfully processing it.
          </p>
          <ul className="privacy-list">
            <li>
              Request correction of the personal information that we hold about you: this enables
              you to have any incomplete or inaccurate information we hold about you corrected.
            </li>
            <li>
              Request erasure of your personal data: this enables you to ask us to delete or remove
              personal data where there is no good reason for us to continue processing it. You also
              have the right to ask us to delete or remove your personal data where you have
              successfully exercised your right to object to processing (see below), where we may
              have processed your information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may not always be able
              to comply with your request of erasure for specific legal reasons which will be
              notified to you, if applicable, at the time of your request.
            </li>
            <li>
              Object to processing of your personal data where we are relying on a legitimate
              interest (or those of a third party) and there is something about your particular
              situation which makes you want to object to processing on this ground as you feel it
              impacts on your fundamental rights and freedoms. You also have the right to object if
              we are processing your personal data for direct marketing purposes. In some cases, we
              may demonstrate that we have compelling legitimate grounds to process your information
              which override your rights and freedoms.
            </li>
            <li>
              Object to processing of your personal data where we are relying on a legitimate
              interest (or those of a third party) and there is something about your particular
              situation which makes you want to object to processing on this ground as you feel it
              impacts on your fundamental rights and freedoms. You also have the right to object if
              we are processing your personal data for direct marketing purposes. In some cases, we
              may demonstrate that we have compelling legitimate grounds to process your information
              which override your rights and freedoms.
            </li>
            <li>
              Request the transfer of your personal data to you or to a third party: we will provide
              to you, or a third party you have chosen, your personal data in a structured, commonly
              used, machine- readable format. Note that this right only applies to automated
              information which you initially provided consent for us to use or where we have used
              the information to perform a contract with you.
            </li>
            <li>
              Right to withdraw consent: where we are relying on consent to process your personal
              data. However, this will not affect the lawfulness of any processing carried out
              before you withdraw your consent.
            </li>
          </ul>
          <p className="privacy-text">
            If you withdraw your consent, we may not be able to provide certain products or services
            to you. We will advise you if this is the case at the time you withdraw your consent. If
            you wish to exercise any of the above, you must send an email to the DPO of West at
            support@West.com, and your request will be further handled. Please note that as per our
            procedures, you will be asked to complete our Data Subject Request form in order to
            verify your request. <br />
            Please be informed the below:
          </p>
          <ul className="privacy-list">
            <li>
              a. usually no fee is required in order to exercise your rights, but we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or excessive or refuse
              to comply with your request in these circumstances
            </li>
            <li>
              a. usually no fee is required in order to exercise your rights, but we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or excessive or refuse
              to comply with your request in these circumstances
            </li>
            <li>
              c. we will always respond to your request within reasonable time and keep you updated.
            </li>
          </ul>
        </div>

        <div className="privacy-section">
          <h2 className="privacy-heading">Further information</h2>
          <p className="privacy-text">
            You can find more information such as what personal information we collect via cookies
            and how we use it by reading our Privacy Policy. <br />
            <br /> If you would like to find out further information about the usage of cookies you
            can visit www.allaboutcookies.org or www.youronlinechoices.eu which give you information
            about privacy issues surrounding internet advertising. If you require any assistance or
            have any questions regarding the use of cookies via the Website, please contact us
            support@West.com.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
