import React, { useEffect } from 'react';
import './Blog.css';

import BlogIMG1 from './../../../Images/BlogIMG1.jpg';
import BlogIMG2 from './../../../Images/BlogIMG2.jpg';
import BlogIMG3 from './../../../Images/BlogIMG3.jpg';
import BlogIMG4 from './../../../Images/BlogIMG4.jpg';
import BlogIMG5 from './../../../Images/BlogIMG5.jpg';
import BlogIMG6 from './../../../Images/BlogIMG6.jpg';
import BlogIMG7 from './../../../Images/BlogIMG7.jpg';
import BlogIMG8 from './../../../Images/BlogIMG8.jpg';
import BlogIMG9 from './../../../Images/BlogIMG9.jpg';
import BlogIMG10 from './../../../Images/BlogIMG10.jpg';

function Blog() {
  useEffect(() => {
    document.title = 'Blog';
  }, []);

  return (
    <div>
      <section class="blog-section">
        <h2 class="blog-title">Our Blog</h2>

        <div class="blog-container">
          <article class="blog-item">
            <div class="blog-image first-blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG1} alt="West Protocol interview" />
            </div>
            <div class="blog-content first-blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">West Protocol Interview: Exclusive News and Plans</h3>
              <p class="blog-text">
                Today, the highly anticipated interview with the West Protocol team was recorded and
                will be released in mid-September. This interview promises to cover everything you
                want to know about the project, from short-term to long-term plans. Despite current
                market conditions, West Protocol continues to thrive. The team will explain in
                detail why the market doesn't hinder their success and how they plan to overcome
                challenges. Additionally, an exciting event announcement is scheduled for this week,
                enhancing user comfort and convenience. A new exclusive plan is set to launch at the
                beginning of winter, available for early access to current users. "We want to thank
                our users for their continuous support and trust," said Richard Davis, CEO of West
                Protocol. "Your trust and enthusiasm inspire us to create the best solutions." Don’t
                miss this major interview, revealing all key points and upcoming innovations from
                West Protocol!
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG2} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">
                West Protocol Announces Mobile App Launch by the End of This Year!
              </h3>
              <p class="blog-text">
                West Protocol is excited to announce the upcoming launch of its mobile application
                by the end of this year! This highly anticipated app will bring all the essential
                features of the platform directly to your iOS and Android devices, offering an
                intuitive interface, high performance, and advanced security. With instant access to
                your personal account via FACE ID and seamless cross-platform compatibility, the app
                is designed to enhance user experience by making interactions with West Protocol
                even more convenient and efficient. Stay tuned for more updates as we approach the
                release!
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG3} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">KYC Verification in 48 Hours with Just One Document </h3>
              <p class="blog-text">
                We’re excited to announce a new feature for West Protocol users: preliminary KYC
                verification can now be completed within just 48 hours with a single document! This
                option is available to all users upon request, even if they do not currently have a
                deposit open. While KYC remains optional and most users won’t need it, this process
                is designed to enhance account security and meet regulatory standards. For
                verification, simply provide an identity document such as a passport or driver’s
                license. To initiate the process or if you have any questions, email us at
                dpo@West.com. We’re committed to making our platform more secure and user-friendly
                based on your feedback.
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG4} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">
                Launch of the West Token: Get +20% on Your Deposit and Join the Exclusive Token
                Drop!{' '}
              </h3>
              <p class="blog-text">
                We are pleased to announce the launch of our own token, which will become a key
                element of the West Protocol ecosystem, significantly enhancing the efficiency and
                profitability of our platform. To celebrate this milestone, all users can receive a
                +20% bonus on any new deposits made in our investment plans until August 31. This
                bonus is designed to create a substantial liquidity pool for the token, which will
                play a crucial role in reducing fees when transferring MEV bot capital across
                different crypto networks, thereby increasing their efficiency. Additionally, all
                VIP+ investors will automatically receive a token drop as a bonus, while other
                participants will receive tokens proportional to their deposit amounts during the
                promotion. This is a unique, one-time opportunity to increase your deposit by 20%
                and become part of the next phase of our platform’s development. Don’t miss out!
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG5} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">
                Join the West Protocol Team: We Are Hiring a Senior Solidity Developer
              </h3>
              <p class="blog-text">
                We are pleased to announce a new opportunity at West Protocol! We are seeking an
                experienced Senior Solidity Developer to join our team. At West Protocol, you will
                work on cutting-edge blockchain projects, receive a competitive salary and bonuses,
                and enjoy a flexible schedule with remote work options. We offer professional
                development programs in a friendly and supportive environment. If you are passionate
                about contributing to the blockchain industry, please send your resume and portfolio
                to job@West.com.
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG6} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">West Protocol Goes Global: New Languages Added </h3>
              <p class="blog-text">
                We are pleased to announce that West Protocol is expanding its reach! We have added
                support for new languages: Spanish, Italian, Vietnamese, and French, making our
                service even more convenient for all users. Now you can enjoy all the benefits of
                West Protocol in your native language! Additionally, Filipino and German will be
                added this week. Moreover, we are launching an active advertising campaign in new
                countries and expect a significant increase in the number of new users. This means
                that spots for the best subscription plans will fill up faster; for the VIP+ plan,
                only 13 spots remain out of the initial 50. To take advantage of the most favorable
                offers, we recommend updating your subscription plan in the coming days.
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG7} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">Now withdrawals start from $10 </h3>
              <p class="blog-text">
                We are delighted to announce a significant update to our service. Starting today, we
                have reduced the minimum withdrawal amount on our platform to $10. This change
                brings several key benefits: • Increased convenience for all our users. • Easier
                access to your funds. • More flexible conditions for new investors. This step has
                been made possible thanks to improvements in our technology and the expertise of our
                programmers, allowing us to reduce fees and network costs. We are committed to
                ensuring maximum transparency and convenience for our clients, and this reduction in
                the minimum withdrawal amount is an important step in that direction. We thank you
                for your trust and support. Our team continues to work on enhancing the service to
                provide you with the best investment experience.
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG8} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">The West Protocol adds support for SOLANA (SOL) </h3>
              <p class="blog-text">
                We are pleased to announce that the UNUMANTIC protocol now supports Solana (SOL).
                This update opens up new horizons for users who want to take advantage of one of the
                fastest growing blockchain platforms. Solana has established itself as the leading
                platform for decentralized applications and finance (DeFi), providing unique
                technological advantages and simply huge volumes to work with, more than 400
                thousand tokens were launched in May alone. The integration of Solana into the
                UNUMANTIC protocol will allow users to replenish their balance using SOL, and for
                the platform itself this is a new market in which we are launching our bot network.
                We continue to improve our platform, striving to offer the most innovative and
                profitable solutions. Join the UNUMANTIC PROTOCOL and discover new investment
                opportunities!
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG9} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">
                A revolution in the investment world: West Protocol rewrites the rules of referral
                marketing
              </h3>
              <p class="blog-text">
                West Protocol is a next-generation investment platform that creates unique
                opportunities for investors. A prime example is the success story of Jake Smith, a
                young photographer from Los Angeles. Thanks to his activity on West Protocol, Jake
                not only increased his income by earning his first $700, but also went on a cool
                vacation to the Burning Man Festival. This money was just enough for Jake to fulfill
                his dream of traveling to the festival, which had seemed out of reach due to
                financial constraints. West Protocol breaks the stereotypes of investing by making
                success accessible to everyone. Their referral network creates a unique ecosystem of
                interaction that fosters growth and support among members.
              </p>
            </div>
          </article>
          <article class="blog-item">
            <div class="blog-image">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <img src={BlogIMG10} alt="West Protocol app launch" />
            </div>
            <div class="blog-content">
              <div className="plus plus-left-top"></div>
              <div className="plus plus-left-bottom"></div>
              <div className="plus plus-right-top"></div>
              <div className="plus plus-right-bottom"></div>
              <p class="blog-date">Aug 6, 2024 | 16:00</p>
              <h3 class="blog-heading">
                WEST PROTOCOL is proud to announce the release of its new financial arbitrage bot{' '}
              </h3>
              <p class="blog-text">
                WEST PROTOCOL is proud to announce the release of its latest project, a unique
                financial arbitrage bot equipped with a patented risk management engine. This bot
                will automatically adapt to your business structure, using advanced technology and
                machine learning algorithms to analyze prices in the financial markets. Our
                proprietary risk management engine, called RiskGuard™, provides maximum protection
                for your investment and minimizes potential losses. RiskGuard™ actively monitors and
                evaluates risks in the market and automatically adjusts trading strategies to ensure
                stable and secure bot operation. WEST PROTOCOL is confident that its new arbitrage
                bot equipped with the unique RiskGuard™ risk management mechanism, will become an
                indispensable tool for traders and investors, opening new perspectives in the
                financial markets.
              </p>
            </div>
          </article>
        </div>
      </section>
    </div>
  );
}

export default Blog;
