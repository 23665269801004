import React, { useEffect } from 'react';
import './LogIn.css';
import Numbers from './../../../Images/Numbers.jpg';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthenticated } from './../../../Redux/Slices/AuthenticationSlice';

function LogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LoginAc = () => {
    dispatch(setAuthenticated(true));
    navigate('/dashboard');
  };

  useEffect(() => {
    document.title = 'Log In';
  }, []);

  return (
    <div className="page">
      <div className="register">
        <div className="register-container">
          <h2 className="register-title">Log in to your account</h2>

          <form className="register-form">
            <div className="input-group">
              <input className="input-register" type="email" placeholder="E-mail" required />
            </div>

            <div className="input-group-row">
              <input
                className="input-register"
                type="password"
                placeholder="Password or 2FA code"
                required
              />
            </div>

            <div className="input-group-row">
              <input
                className="input-register input-register-code"
                type="text"
                placeholder="Code from image"
                required
              />
              <div className="captcha-image">
                <img src={Numbers} alt="Captcha" />
              </div>
            </div>

            <button onClick={LoginAc} type="submit" className="create-account-btn">
              Login
            </button>
          </form>

          <div className="form-footer">
            <a onClick={() => navigate('/PasswordReset')}>Forgot Your Password?</a>
            <span>
              Don’t have an account? <a onClick={() => navigate('/SingUP')}>Sing Up</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
