import React, { useEffect, useRef } from 'react';
import './Home.css';
import WestProtocolLogo from './../../../Images/WestProtocolLogo.svg';
import Fingerprint from './../../../Images/Fingerprint.png';
import CommunityTrustSlider from '../../Slider/CommunityTrustSlider';
import FAQItem from '../../FAQItem/FAQItem';

function Home() {
  const refAnimSellRef = useRef();
  const refAnimBuyRef = useRef();

  const faqData = [
    {
      question: 'How to make a deposit for MEV bot operations?',
      answer:
        'To make a deposit, please click the "Sign Up" button and complete the registration process. Once registered, navigate to the "Investment" section and choose the investment plan that best suits your needs. Select the cryptocurrency you wish to invest in, specify the desired amount, and click "Invest" to complete the transaction.',
    },
    {
      question: 'Which cryptocurrencies do you accept?',
      answer:
        'We accept a wide range of popular cryptocurrencies, including Bitcoin, Ethereum, and more.',
    },
    {
      question: 'Deposit limits and quantity',
      answer:
        'The minimum deposit amount is $100. Funds will be credited depending on network conditions. If we detect that a user has more than one account, all funds will be held until further notice. There is no limit to the number of deposits you can make.',
    },
    {
      question: 'How are interest rates calculated on my account?',
      answer: 'Interest rates are calculated based on your selected investment plan.',
    },
    {
      question: 'How are interest rates calculated on my account?',
      answer: 'Interest rates are calculated based on your selected investment plan.',
    },
    {
      question: 'When can I withdraw my investment and profits?',
      answer:
        'We will process your withdrawal request as soon as possible, but please allow some time for the network to confirm the transaction. Your investment will be returned to you at the end of the investment period. You will receive your initial earnings exactly 24 hours after making the deposit. You can withdraw funds at any time. The time it takes to process a withdrawal depends on the cryptocurrency network, but there are no delays on our end. Typically, funds are credited to your wallet within a few minutes after the request is confirmed. The maximum processing time for a withdrawal is 24 hours.',
    },
    {
      question: 'What if I cant log into my account because I forgot my password?',
      answer:
        'If you forget your password, click the "Forgot Password" button and enter your username or email address. You will receive a password reset email.',
    },
    {
      question: 'Who manages the funds and what are the guarantees?',
      answer:
        'Funds are managed by an automated algorithm of the MEV Bot network. We are a licensed and registered company, our activities are fully legal, and we have all the necessary licenses and permissions. Our reputation has been validated by more than 5,000 satisfied investors, and we receive numerous positive reviews. We are regularly featured in the media.',
    },
    {
      question: 'Is it possible to use the WEST PROTOCOL platform on mobile devices?',
      answer:
        'Yes, the Unimatic Protocol platform is available for use on mobile devices. We have optimized the site for easy access to your account and management anytime, anywhere.',
    },
  ];

  useEffect(() => {
    document.title = 'Home';
  }, []);

  return (
    <div className="page">
      <div className="home-container">
        <section className="home-section delete-border-radius">
          <div className="container">
            <h1 className="main-heading">
              Scale <span className="highlight">West</span> Protocol and <br />
              earn a share of MEV bots <br /> profits every day
            </h1>
            <p className="subheading">
              Get access to MEV bots network and forget about market volatility. 600% APR with daily
              payouts.
            </p>
            <div className="buttons">
              <button className="primary-btn">
                Go to earn Zone <i></i>
              </button>
              <button className="secondary-btn">
                Watch video <i></i>
              </button>
            </div>
            <div className="social-icons">
              {/* Иконки можно добавить через изображения или библиотеки иконок */}
              <a href="https://discord.com" className="icon discord"></a>
              <a href="https://telegram.org" className="icon telegram"></a>
              <a href="https://telegram.org" className="icon X"></a>
            </div>
          </div>
        </section>

        <section className="stats-section">
          <div className="stats-container">
            <div className="stat-card highlighted-card">
              <h3>High rating and reviews</h3>
              <div className="rating">
                <div className="trustpilot-logo">Trustpilot</div>
                <div className="stars">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="stat-card">
              <h4>24H volume</h4>
              <p>$15.2M</p>
            </div>
            <div className="stat-card">
              <h4>All time volume</h4>
              <p>$100M+</p>
            </div>
            <div className="stat-card">
              <h4>Protocol trades</h4>
              <p>250k+</p>
            </div>
            <div className="stat-card">
              <h4>All rewards paid</h4>
              <p>$7.5M+</p>
            </div>
          </div>
        </section>

        <section className="about-section delete-border-radius">
          <h2 className="about-section-title">What is West Protocol</h2>

          <div className="about-section-container">
            <div className="about-text">
              <h3>Independence from market, clear algorithm, guaranteed profits.</h3>
              <p>
                The profit-making method involves monitoring transactions across multiple blockchain
                networks. It buys a coin right before a transaction that significantly affects the
                coin's price, and then sells it immediately after the price changes.
              </p>
            </div>
            <div className="about-card">
              <div className="about-card-static">
                <div className="logo">
                  <img src={WestProtocolLogo} alt="West Protocol Logo" className="about-logo" />
                </div>
                <p className="disclaimer">
                  The West Protocol affects the rate on the moment to make a profit.
                </p>
              </div>
              <div className="trade-list">
                <div className="trade-item">
                  <div className="trade-btn buy">BUY</div>
                  <span className="price">$45,640.00</span>
                  <span className="change positive">▲ 2.2%</span>
                </div>
                <div className="trade-item">
                  <div className="trade-btn buy">BUY</div>
                  <span className="price">$45,640.00</span>
                  <span className="change positive">▲ 2.2%</span>
                </div>
                <div ref={refAnimSellRef} className="trade-item trade-item-sell">
                  <div className="trade-btn sell">SELL</div>
                  <span className="price">$12,350.00</span>
                  <span className="change negative">▼ 0.2%</span>
                </div>
                <div className="trade-item">
                  <div className="trade-btn buy">BUY</div>
                  <span className="price">$45,640.00</span>
                  <span className="change positive">▲ 2.2%</span>
                </div>
                <div ref={refAnimBuyRef} className="trade-item trade-item-buy">
                  <div className="trade-btn buy">BUY</div>
                  <span className="price">$12,100.00</span>
                  <span className="change positive">▲ 2.2%</span>
                </div>
                <div className="profit">+$250</div>
              </div>
            </div>
          </div>
        </section>

        <section className="security-section">
          <div className="security-section-container">
            <h2 className="security-title">Security</h2>
            <p className="security-text">Several data centers in different countries</p>
            <div className="securitu-cards">
              <div className="large-cards">
                <div className="grid-item large-card">
                  <h3>Capacity of our data centers</h3>
                  <p className="stat">» 1MW</p>
                </div>
                <div className="grid-item uptime-card">
                  <h3>
                    <i className="green-dot"></i> Uptime of work
                  </h3>
                  <p className="stat">» 99%</p>
                </div>
              </div>
              <div className="grid-container delete-border-radius">
                <div className="grid-item small-card">
                  <div className="small-card-img"></div>
                  <p>Distributed system with a high level of security.</p>
                </div>
                <div className="grid-item small-card">
                  <div className="small-card-img"></div>
                  <p>Readiness to scale and deploy new capacities.</p>
                </div>
                <div className="grid-item small-card">
                  <div className="small-card-img"></div>
                  <p>No problems with equipment downtime.</p>
                </div>
                <div className="grid-item small-card">
                  <div className="small-card-img"></div>
                  <p>24/7 operation of all systems.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="verify-section">
          <div className="verify-section-container">
            <div className="text-content">
              <h2>
                Don't trust, <span className="highlight">verify</span>
              </h2>
              <p>
                All our transactions are recorded on the blockchain. Check it out and be confident.
              </p>
              <button className="learn-more-btn">
                Learn more <i></i>
              </button>
            </div>
            <div className="image-content">
              <img src={Fingerprint} alt="Fingerprint Icon" className="fingerprint-img" />
            </div>
          </div>
        </section>

        <section className="investors-section">
          <h2>For investors</h2>
          <div className="investors-container">
            {/* Левая часть с изображением и текстом */}
            <div className="investment-info">
              <div className="investment-text">
                <h3>West has launched investment options accessible to all.</h3>
                <p>
                  Secure, assured, and effortless earnings in cryptocurrency! Put your money to
                  work, scale the West protocol, increase its performance, and reap rewards of up to
                  +90% monthly, with payouts every day.
                </p>
              </div>
            </div>

            {/* Правая часть с таблицей */}
            <div className="trades-section">
              <h4>
                <i className="green-dot"></i> Last trades by Protocol
              </h4>
              <table className="trades-table">
                <tbody>
                  <tr>
                    <td className="td-details">
                      <p className="td-details-p">Details</p> 0x22af...b99d
                    </td>
                    <td>
                      <p className="td-details-p">Cost</p> $9018.00
                    </td>
                    <td>
                      <p className="td-details-p">Revenue</p> $47321.00
                    </td>
                  </tr>
                  <tr>
                    <td className="td-details-p">
                      <p class="td-details-p">Details</p> 0x22af...b99d
                    </td>
                    <td>
                      <p className="td-details-p">Cost</p> $9018.00
                    </td>
                    <td>
                      <p className="td-details-p">Revenue</p> $47321.00
                    </td>
                  </tr>
                  <tr>
                    <td className="td-details">
                      <p className="td-details-p">Details</p> 0x22af...b99d
                    </td>
                    <td>
                      <p className="td-details-p">Cost</p> $9018.00
                    </td>
                    <td>
                      <p className="td-details-p">Revenue</p> $47321.00
                    </td>
                  </tr>
                  <tr>
                    <td className="td-details">
                      <p className="td-details-p">Details</p> 0x22af...b99d
                    </td>
                    <td>
                      <p className="td-details-p">Cost</p> $9018.00
                    </td>
                    <td>
                      <p className="td-details-p">Revenue</p> $47321.00
                    </td>
                  </tr>
                </tbody>
              </table>
              <a href="#" className="join-invest-btn">
                Join and Invest <span></span>
              </a>
            </div>
          </div>

          {/* Нижние кнопки */}
          <div className="bottom-buttons">
            <a href="#" className="analytics-btn">
              Independent Analytics <span>→</span>
            </a>
            <a href="#" className="whitepaper-btn">
              Whitepaper <span>→</span>
            </a>
          </div>
        </section>

        <section className="steps-section">
          <h2>Easy steps to invest</h2>
          <div className="steps-container">
            <div className="step-card step-highlighted-card">
              <div className="step-header">
                <div className="step-number-home">01</div>
                <div className="step-icon step-icon-1"></div>
              </div>
              <h3>Signing up</h3>
              <p>Create your personal account in few minutes.</p>
            </div>

            <div className="step-card">
              <div className="step-header">
                <div className="step-number-home">02</div>
                <div className="step-icon step-icon-2"></div>
              </div>
              <h3>Choose plan</h3>
              <p>Several options for managing investment money.</p>
            </div>

            <div className="step-card">
              <div className="step-header">
                <div className="step-number-home">03</div>
                <div className="step-icon step-icon-3"></div>
              </div>
              <h3>Deposit</h3>
              <p>Make a deposit. More than 8 payment methods available.</p>
            </div>

            <div className="step-card">
              <div className="step-header">
                <div className="step-number-home">04</div>
                <div className="step-icon step-icon-4"></div>
              </div>
              <h3>Profit</h3>
              <p>View your earnings and withdraw profits every day!</p>
            </div>
          </div>
        </section>

        <section className="community-trust-section delete-border-radius">
          <h2>Community trust</h2>
          <p>An active community ensures the success of the Protocol.</p>
          <a href="#" className="explore-btn">
            Explore community <i></i>
          </a>
          <CommunityTrustSlider />

          <section class="community-section">
            <h2>
              Join our community in
              <a href="https://t.me/yourtelegramlink" class="telegram-link">
                Telegram
              </a>
            </h2>
            <p>Stay informed: News, Promo, and Social networking.</p>
          </section>

          <section className="faq-section">
            <h2>FAQ</h2>
            {faqData.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </section>
        </section>
      </div>
    </div>
  );
}

export default Home;
