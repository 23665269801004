import { createSlice } from '@reduxjs/toolkit';

const MenuSlice = createSlice({
  name: 'menu',
  initialState: { isOpen: true, isOpenWallet: false },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setIsOpenWallet: (state, action) => {
      state.isOpenWallet = action.payload;
    },
  },
});

export const { setIsOpen, setIsOpenWallet } = MenuSlice.actions;
export default MenuSlice.reducer;
