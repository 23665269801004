import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-left">
          <p className="footer-left-title">West Protocol © 2024 — All rights reserved.</p>
          <p className="footer-text">
            WEST PROTOCOL LTD is a trademark of WEST LLC, a US-based FinTech company. <br />
            Principal office street address: The Broadgate Tower, 20 Primrose Street, <br />
            London, England, EC2A 2ES
          </p>
          <p className="footer-text">
            +1 (720) 744 3019 (US) <br />
            +44 (204) 577 1844 (UK)
          </p>
        </div>

        <div className="footer-right">
          <h4>Registrations:</h4>
          <p className="footer-text">62003 - Financial management</p>
          <div className="footer-buttons">
            <button
              onClick={() => navigate('/SingUP')}
              className="footer-btn footer-btn-registration">
              Registration
            </button>
            <button onClick={() => navigate('/Policy')} className="footer-btn footer-btn-outline">
              Documents
            </button>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="footer-text">
          <a href="mailto:support@westprotocol.com">support@westprotocol.com</a>
        </p>
        <p className="privacy-policy">
          <a className="footer-text" onClick={() => navigate('/Policy')}>
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
