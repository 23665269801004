import { createSlice } from '@reduxjs/toolkit';

const InvestSlice = createSlice({
  name: 'invest',
  initialState: { currency: {} },
  reducers: {
    setInvestData: (state, action) => {
      state.currency = action.payload;
    },
  },
});

export const { setInvestData } = InvestSlice.actions;
export default InvestSlice.reducer;
