import React, { useState } from 'react';
import './Tooltip.css';

const Tooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="tooltip-container">
      <i
        className="question-icon"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}></i>
      {showTooltip && <div className="tooltip-message">Message!</div>}
    </div>
  );
};

export default Tooltip;
