import React, { useEffect } from 'react';
import './OperationConfirmation.css';
import Numbers from './../../../Images/Numbers.jpg';
import { useNavigate } from 'react-router-dom';

function PasswordReset() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Operation Confirmation';
  }, []);

  return (
    <div className="page">
      <div className="register">
        <div className="register-container">
          <h2 className="register-title">Operation confirmation</h2>

          <form className="register-form">
            <div className="input-group">
              <input className="input-register" type="email" placeholder="Code" required />
            </div>

            <div className="input-group-row">
              <input
                className="input-register input-register-code"
                type="text"
                placeholder="Code from image"
                required
              />
              <div className="captcha-image">
                <img src={Numbers} alt="Captcha" />
              </div>
            </div>

            <button
              onClick={() => navigate('/PasswordResetMessage')}
              type="submit"
              className="create-account-btn">
              Execute
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
