import React, { useState, useRef } from 'react';
import './FAQItem.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null); // Реф для доступа к высоте контента

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={toggleOpen}>
        <h3>{question}</h3>
        <span className="toggle-icon">{isOpen ? '-' : '+'}</span>
      </div>
      <div
        className="faq-answer-wrapper"
        style={{
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0px', // Используем высоту контента для анимации
        }}>
        <p className="faq-answer" ref={contentRef}>
          {answer}
        </p>
      </div>
    </div>
  );
};

export default FAQItem;
