import React, { useEffect, useRef, useState } from 'react';
import './Withdraw.css';
import WalletItem from '../WalletItem/WalletItem';

function Withdraw() {
  useEffect(() => {
    document.title = 'Withdraw';
  }, []);

  return (
    <div className="main-block-container">
      <h2 className="main-block-title">Withdraw</h2>

      <div className="wallet-items">
        <div className="wallet-items-container">
          <div className="wallet-items-header">
            <p className="wallet-header-name">CURRENCY</p>
            <p className="wallet-header-name">BALANCE</p>
            <p className="wallet-header-name">MIN. AMOUNT</p>
            <p className="wallet-header-name">MAX. AMOUNT</p>
            <p className="wallet-header-name">WITHDRAWAL TIME</p>
            <p className="wallet-header-name">FEE</p>
          </div>
          <div className="wallet-list">
            <WalletItem /> <WalletItem />
            <WalletItem />
            <WalletItem />
            <WalletItem />
            <WalletItem />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdraw;
