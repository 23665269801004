import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Pages/Home/Home';
import About from './Components/Pages/About/About';
import Security from './Components/Pages/Security/Security';
import Bounty from './Components/Pages/Bounty/Bounty';
import Support from './Components/Pages/Support/Support';
import Blog from './Components/Pages/Blog/Blog';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import SingUp from './Components/Pages/SingUp/SingUp';
import LogIn from './Components/Pages/LogIn/LogIn';
import PasswordReset from './Components/Pages/PasswordReset/PasswordReset';
import PasswordResetMessage from './Components/Pages/PasswordResetMessage/PasswordResetMessage';
import PasswordResetFinalMessage from './Components/Pages/PasswordResetFinalMessage/PasswordResetFinalMessage';
import OperationConfirmation from './Components/Pages/OperationConfirmation/OperationConfirmation';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy/PrivacyPolicy';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from './Components/PersonalAccountPages/Dashboard/Dashboard';
import Invest from './Components/PersonalAccountPages/Invest/Invest';
import Partners from './Components/PersonalAccountPages/Partners/Partners';
import Settings from './Components/PersonalAccountPages/Settings/Settings';
import Withdraw from './Components/PersonalAccountPages/Withdraw/Withdraw';
import PersonalAccountPagesHeader from './Components/PersonalAccountPages/PersonalAccountPagesHeader/PersonalAccountPagesHeader';
import LeftPanel from './Components/PersonalAccountPages/LeftPanel/LeftPanel';
import Wallet from './Components/PersonalAccountPages/Wallet/Wallet';
import AddFunds from './Components/PersonalAccountPages/AddFunds/AddFunds';
import WithdrawDetails from './Components/PersonalAccountPages/WithdrawDetails/WithdrawDetails';
import { useEffect, useRef } from 'react';

function App() {
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated); // Получаем значение из состояния
  const PersonalAccountRef = useRef();

  return (
    <div className="App">
      <div className="App-container">
        {isAuthenticated ? (
          <div className="PersonalAccountPage">
            <Router>
              <LeftPanel PersonalAccount={PersonalAccountRef} />
              <div ref={PersonalAccountRef} className="PersonalAccount-container">
                <PersonalAccountPagesHeader />
                <div className="PersonalAccount-main-block">
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/invest" element={<Invest />} />
                    <Route path="/AddFunds" element={<AddFunds />} />
                    <Route path="/Withdraw" element={<Withdraw />} />
                    <Route path="/WithdrawDetails" element={<WithdrawDetails />} />
                    <Route path="/Partners" element={<Partners />} />
                    <Route path="/Settings" element={<Settings />} />
                  </Routes>
                  <Footer />
                </div>
              </div>
              <Wallet />
            </Router>
          </div>
        ) : (
          <Router>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/security" element={<Security />} />
              <Route path="/bounty" element={<Bounty />} />
              <Route path="/support" element={<Support />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/LogIn" element={<LogIn />} />
              <Route path="/SingUP" element={<SingUp />} />
              <Route path="/PasswordReset" element={<PasswordReset />} />
              <Route path="/PasswordResetMessage" element={<PasswordResetMessage />} />
              <Route path="/PasswordResetFinalMessage" element={<PasswordResetFinalMessage />} />
              <Route path="/OperationConfirmation" element={<OperationConfirmation />} />
              <Route path="/Policy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
          </Router>
        )}
      </div>
    </div>
  );
}

export default App;
