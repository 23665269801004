import React, { useEffect, useState } from 'react';
import './WalletItem.css'; // стили вынесены в отдельный файл
import ETH from './../../../Images/Token3.svg';
import { useNavigate } from 'react-router-dom';

const WalletItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`eth-card ${isOpen ? 'open' : ''}`}>
      <div className={`eth-card-header ${isOpen ? 'open-card-header' : ''}`} onClick={toggleCard}>
        <div className="balance-info">
          <div className="balance-info-cell currency">
            <img src={ETH} alt="ETH Logo" className="eth-logo" />
            <div className="currency-info">
              <span>ETH</span>
              <small>Ethereum</small>
            </div>
          </div>
          <div className="balance-info-cell">
            <p>
              2000.00 <span className="signature">ETH</span>
            </p>
            <p className="balance-info-cell-bottom">$2000</p>
          </div>
          <div className="balance-info-cell">
            <p>
              100.00 <span className="signature">ETH</span>
            </p>
            <p className="balance-info-cell-bottom">$100</p>
          </div>
          <div className="balance-info-cell">
            <p>
              1,000,000.00 <span className="signature">ETH</span>
            </p>
            <p className="balance-info-cell-bottom">$1,000,000</p>
          </div>
          <div className="balance-info-cell">
            <p className="balance-info-cell-bottom">Up to 24 hours</p>
          </div>
          <div className="balance-info-cell">
            <p className="balance-info-cell-bottom">0.00%</p>
          </div>
          <div className="balance-info-cell balance-info-cell-button">
            <button className="toggle-button">{isOpen ? '-' : '+'}</button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="eth-card-content">
          <div className="wallet-section">
            <div className="wallet-section-item wallet">
              <p>Your wallet</p>
              <strong>
                0.001 <span className="signature">ETH</span>
              </strong>
            </div>
            <div className="wallet-section-item wallet">
              <p>Your wallet</p>
              <strong>ghrj/bknjr/njt/bkrni</strong>
            </div>
            <button className="wallet-section-item set-wallet">Set Wallet</button>
            <button
              onClick={() => navigate('/WithdrawDetails')}
              className="wallet-section-item withdraw">
              Withdraw
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletItem;
